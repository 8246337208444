<template>
  <div id="LotsPage" class="min-h-screen relative bg-gradient-to-r from-dark-800 via-primary to-dark-800 text-light-50 transition-colors duration-500">
    <div class="flex justify-between items-center top-0 z-10 p-4">
      <!-- Left-side title -->
      <h1 class="text-2xl font-bold text-light">Lot Management</h1>

      <!-- Right-side buttons -->
      <div class="flex gap-4">
        <button @click="openNewLotDialog" class="btn text-dark bg-secondary-100 hover:bg-secondary-600 hover:text-white">Add New Lot</button>
        <button @click="openNewLocationDialog" class="btn text-dark bg-secondary-100 hover:bg-secondary-600 hover:text-white">Add New Location</button>
      </div>
    </div>

    <!-- Add padding between buttons and tabs -->
    <div class="mb-8 border-b border-gray-200 dark:border-gray-700">
      <ul class="flex flex-wrap -mb-px text-sm text-light font-medium text-center">
        <li
            class="me-2"
            role="presentation"
            v-for="location in orderedLocations"
            :key="location.id">
          <button
              @click="setLocation(location)"
              :class="{
        'inline-block p-4 border-b-2 rounded-t-lg': true,
        'text-dark bg-tertiary-100 border-tertiary-500': location.id === selectedLocationId,
        'text-gray-400 border-transparent hover:text-tertiary-400 hover:border-tertiary-400': location.id !== selectedLocationId
      }"
              type="button"
              role="tab"
              :aria-selected="location.id === selectedLocationId">
            {{ location.name }}
          </button>
        </li>
      </ul>
      </div>

      <div v-if="selectedLocationId" class="bg-dark-100 text-light-100">
        <div v-if="!isMobilePortrait" class="overflow-x-auto">
          <div class="flex justify-end pr-4 pb-4">
            <button class="btn bg-secondary-100 text-dark hover:bg-tertiary-500 w-full md:w-auto">Edit Location</button>
          </div>
          <table class="table w-full p-6">
            <thead class="bg-dark-100 text-light-100">
            <tr>
              <th class="p-3">Lot Name</th>
              <th class="p-3">Strain</th>
              <th class="p-3">Number of Plants</th>
              <th class="p-3">Stage</th>
              <th class="p-3 text-right">Actions</th>
            </tr>
            </thead>
            <tbody class="bg-dark-100 text-light-100">
            <tr v-for="lot in filteredLots(selectedLocationId)" :key="lot.id" class="hover:bg-dark-600 transition">
              <td class="p-3">{{ lot.name }}</td>
              <td class="p-3">{{ lot.strain }}</td>
              <td class="p-3">{{ lot.numberOfPlants }}</td>
              <td class="p-3">{{ lot.stage.name }}</td>
              <td class="flex justify-end gap-2 py-2">
                <button @click="openQRCodeDialog(lot)" class="btn bg-secondary-100 text-dark hover:bg-tertiary-500 w-full md:w-auto">Show QR Code</button>
                <button class="btn bg-secondary-100 text-dark hover:bg-tertiary-500 w-full md:w-auto">View / Edit Lot</button>
              </td>
            </tr>
            </tbody>
          </table>
        </div>

        <div v-else class="grid grid-cols-1 gap-4">
          <div v-for="lot in filteredLots(selectedLocationId)" :key="lot.id" class="card bg-primary-600 text-gray-100">
            <div class="card-body">
              <h2 class="card-title">{{ lot.name }}</h2>
              <p>Strain: {{ lot.strain }}</p>
              <p>Number of Plants: {{ lot.numberOfPlants }}</p>
              <p>Stage: {{ lot.stage.name }}</p>
              <div class="card-actions">
                <button class="btn bg-secondary-200 text-dark hover:bg-gray-100 w-full md:w-auto"><i class="fa fa-pencil"></i></button>
                <button @click="openDeleteLotConfirmDialog(lot)" class="btn text-dark bg-tertiary-100 hover:bg-tertiary-700 w-full md:w-auto"><i class="fa fa-trash"></i> Delete Lot</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      
<!--  New Lot Dialog-->
      <transition enter-active-class="transition-opacity duration-300 ease-linear" leave-active-class="transition-opacity duration-300 ease-linear">
        <div role="dialog" v-if="newLotDialogVisible" class="modal modal-open flex items-center justify-center fixed inset-0 z-50 bg-dark-800 bg-opacity-50">
          <div class="modal-box bg-dark-50 border-light border-2 rounded-lg shadow-lg text-light-50">
            <NewLotForm :locations="locations" @cancelNewLot="newLotDialogVisible = false" @submit="handleNewLotSubmit" />
          </div>
        </div>
      </transition>

      
<!--  New Location Dialog-->
      <transition enter-active-class="transition-opacity duration-300 ease-linear" leave-active-class="transition-opacity duration-300 ease-linear">
        <div role="dialog" v-if="newLocationDialogVisible" class="modal modal-open flex items-center justify-center fixed inset-0 z-50 bg-dark-800 bg-opacity-50">
          <div class="modal-box bg-dark-50 border-light border-2 rounded-lg shadow-lg text-light-50">
            <NewLocationForm @cancelNewLocation="newLocationDialogVisible = false" @submit="handleNewLocationSubmit" />
          </div>
        </div>
      </transition>

      
<!--  Delete Location Confirmation Dialog -->
      <transition enter-active-class="transition-opacity duration-300 ease-linear" leave-active-class="transition-opacity duration-300 ease-linear">
        <div role="alertdialog" v-if="deleteLocationConfirmDialogVisible" class="modal modal-open flex items-center justify-center fixed inset-0 z-50 bg-dark-800 bg-opacity-50">
          <div class="modal-box bg-dark-50 border-light border-2 rounded-lg shadow-lg text-light-50">
            <DeleteLocationConfirmation :location="selectedLocation" @cancel-delete="deleteLocationConfirmDialogVisible = false" @confirm-delete="deleteLocation" />
          </div>
        </div>
      </transition>

      
<!--  Delete Lot Confirmation Dialog-->
      <transition enter-active-class="transition-opacity duration-300 ease-linear" leave-active-class="transition-opacity duration-300 ease-linear">
        <div role="alertdialog" v-if="deleteLotConfirmDialogVisible" class="modal modal-open flex items-center justify-center fixed inset-0 z-50 bg-dark-800 bg-opacity-50">
          <div class="modal-box bg-dark-50 border-light border-2 rounded-lg shadow-lg text-light-50">
            <DeleteLotConfirmation />
          </div>
        </div>
      </transition>

      
<!--      Print QR Code Preview Dialog-->
    <transition enter-active-class="transition-opacity duration-300 ease-linear" leave-active-class="transition-opacity duration-300 ease-linear">
        <div v-if="qrCodeModalVisible" class="modal modal-open flex items-center justify-center fixed inset-0 z-50 bg-dark-800 bg-opacity-50">
          <div class="modal-box bg-dark-50 border-light border-2 rounded-lg shadow-lg text-light-50">
            <QRPreview :lot="selectedLot" @cancelPreview="qrCodeModalVisible = false" />
          </div>
        </div>
      </transition>

      
<!--      Lot Details Sidebar-->
    <transition
        enter-active-class="transition-transform duration-300 ease-linear"
        leave-active-class="transition-transform duration-300 ease-linear"
        enter-from-class="translate-x-full"
        enter-to-class="translate-x-0"
        leave-from-class="translate-x-0"
        leave-to-class="translate-x-full">

      <!-- Sidebar content with darker background -->
      <div v-if="detailSidebarVisible" class="fixed inset-y-0 right-0 h-full bg-dark-100 shadow-lg z-50">
        <div class="p-4 h-full" :style="{ height: 'calc(100% - 4rem)', width: '75vw' }">
          <div class="pb-4 flex justify-end">
            <button @click="detailSidebarVisible = false" class="btn bg-tertiary-100 hover:bg-secondary-400">Close Lot Details</button>
          </div>
          <LotDetailPage :lot-id="selectedLot?.id"/>
        </div>
      </div>
    </transition>


      
<!--      Lot Details Sidebar Overlay-->
      <div v-if="detailSidebarVisible" class="fixed inset-0 bg-black bg-opacity-50 z-40 transition-opacity duration-300 ease-linear" @click="detailSidebarVisible = false"></div>
    </div>
  
</template>


<script>
import apiService from '@/services';
import services from '@/services';


import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import LotDetailPage from "@/views/App/LotDetailPage.vue";
import NewLotForm from "@/components/Forms/NewLotForm.vue";
import NewLocationForm from "@/components/Forms/NewLocationForm.vue";
import DeleteLocationConfirmation from "@/components/Forms/DeleteLocationConfirmation.vue";
import DeleteLotConfirmation from "@/components/Forms/DeleteLotConfirmation.vue";
import QRPreview from "@/components/QR/QRPreview.vue";

dayjs.extend(duration);
dayjs.extend(relativeTime);

export default {
  components: {
    QRPreview,
    DeleteLocationConfirmation,
    DeleteLotConfirmation,
    LotDetailPage,
    NewLotForm,
    NewLocationForm    
  },
  async mounted() {
    try {
      await this.loadPage();
    } catch (error) {
      console.error('Error during mounted hook:', error);
    }
  },
  watch: {
    detailSidebarVisible(newVal, oldVal) {
      // Reload the page or perform an action when the sidebar is closed.
      if (!newVal && oldVal) {
        this.loadPage(false); // Assuming `loadPage` is a method to refresh data.
      }
    },
    selectedLocation(newLocation) {
      // Clear global filters or perform actions when selected location changes.
    }
  },
  data() {
    return {
      isLoading: true,
      newLocationName: '',
      newLotName: '',
      selectedLot: null,
      selectedQRCode: null,
      selectedLocation: null,
      selectedLocationId: null,
      locations: [],
      detailSidebarVisible: false,
      newLotDialogVisible: false,
      newLocationDialogVisible: false,
      deleteLocationConfirmDialogVisible: false,
      deleteLotConfirmDialogVisible: false,
      qrCodeModalVisible: false,
    }
  },
  methods: {
    showToast(type, message) {
      
    },
    setLocation(location) {
      this.selectedLocation = location;
      this.selectedLocationId = location.id;
    },
    openNewLotDialog() {
      this.newLotDialogVisible = true;
    },
    openNewLocationDialog() {
      this.newLocationDialogVisible = true;
    },

    async handleNewLotSubmit(data) {
      console.log('Form submitted with:', data);
      await this.loadPage(false);
      this.newLotDialogVisible = false;
    },
    
    async handleNewLocationSubmit(data) {
      await services.addLocation(this.newLocationName);
      await this.loadPage(false);
      
      // reset
      this.newLocationDialogVisible = false;
      this.newLocationName = null;
    },

    openDeleteLocationConfirmDialog() {
      this.deleteLocationConfirmDialogVisible = true; 
    },
    async deleteLocation(data) {
      return;
      // try {
      //   await services.deleteLocation(data);
      //   this.showToast('success', 'Location Deleted');
      //   this.deleteLocationConfirmDialogVisible = false;
      //   await this.loadPage(false);
      // } catch (error) {
      //   this.showToast('error', 'Failed to delete location');
      // }
    },

    openLotDetailsSidebar(lot) {
      this.selectedLot = lot;
      this.detailSidebarVisible = true;
    },
    
    openDeleteLotConfirmDialog(lot) {
      this.selectedLot = lot;
      this.deleteLotConfirmDialogVisible = true;
    },
    async deleteLot() {
      const lot = this.selectedLot;
      console.log(lot);
      try {
        await services.deleteLot(lot.id, {
          reason: this.trashReason
        });

        this.$toast.add({
          severity: 'success',
          summary: 'Success',
          detail: 'Lot moved to Trash',
          life: 3000,
        });

        this.deleteLotConfirmDialogVisible = false; // Close the dialog
      } catch (error) {
        console.error('Error trashing lot:', error);
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Failed to trash lot',
          life: 3000,
        });
      } finally {
        this.trashReason = '';
        this.deleteLotConfirmDialogVisible = false;
      }
    },

    async loadPage(showLoadingScreen = true) {
      if (showLoadingScreen) {
        this.isLoading = true;
      }

      await this.getLocations();
      await this.getLots();
      if (this.locations.length > 0) {
        this.selectedLocationId = this.locations[0].id;
        this.selectedLocation = this.locations[0];
      }

      this.isLoading = false;
    },

    async getLocations() {
      const apiResponse = await services.getLocations();
      this.locations = apiResponse.data.sort((a, b) => a.order_index - b.order_index);
    },

    async getLots() {
      try {
        const response = await apiService.getLots();
        this.lots = response.data.map(lot => ({
          ...lot,
          locationName: lot.location ? lot.location.name : 'Unknown Location',
          stage: lot.stage || {id: null, name: 'Unknown Stage'},
          imageUrl: lot.imageId ? '/img/flower-3-thumbnail.jpeg' : '/img/flower-3-thumbnail.jpeg',
          qrCode: `https://weed-garden.com/product-lots/${lot.id}`
        }));
      } catch (error) {
        console.error('Failed to load lots', error);
      }
    },

    openQRCodeDialog(data) {
      this.selectedLot = data;
      this.qrCodeModalVisible = true;
    }
  },
  computed: {
    orderedLocations() {
      return this.locations.slice().sort((a, b) => a.order_index - b.order_index);
    },
    isMobilePortrait() {
      return window.innerWidth <= 768 && window.matchMedia('(orientation: portrait)').matches;
    },
    filteredLots() {
      return (locationId) => {
        const filterValue = null;
        return this.lots.filter(lot => {
          const matchesLocation = locationId === null || (lot.location && lot.location.id === locationId);
          const matchesGlobalFilter = !filterValue || Object.values(lot).some(
              value => typeof value === 'string' && value.toLowerCase().includes(filterValue)
          );
          return matchesLocation && matchesGlobalFilter;
        });
      };
    }
  }
};

</script>