<template>
  <div class="h-full flex flex-col p-4">
    <div v-if="isLoading" class="flex items-center justify-center h-full">
      <h1 class="text-light-50 text-lg">Loading... Please wait</h1>
    </div>

    <div v-else class="flex flex-col h-full space-y-4">
      <div class="flex justify-between items-center mb-6">
        <button  @click="openEditor" class="btn w-full md:w-auto text-white hover:bg-secondary-600 hover:text-dark">
          Add New Note
          <i class="pi pi-plus"></i>
        </button>
      </div>

      <!-- Search and Filter Controls -->
      <div class="mb-4">
        <label for="filterText" class="block font-semibold mb-2">Filter Notes</label>
        <div class="relative">
          <input v-model="filterText" id="filterText" placeholder="Filter notes..." class="input input-bordered w-full pr-12" />
          <i class="pi pi-search absolute right-4 top-1/2 transform -translate-y-1/2 text-secondary-500"></i>
        </div>
      </div>

      <!-- Notes Content -->
      <div class="overflow-y-auto flex-grow">
        <div v-for="note in filteredNotes" :key="note.id" class="card bg-base-100 shadow-md border border-base-300 mb-4">
          <div class="card-body">
            <h2 class="card-title">{{ note.author }}</h2>
            <p>{{ formatTimestamp(note.timeStamp) }}</p>
            <div v-html="note.content" class="mt-3"></div>
          </div>
        </div>
      </div>
    </div>

    <!-- Editor Modal -->
    <div v-if="editorVisible" class="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div class="bg-primary-100 rounded-lg w-3/4 h-3/4 shadow-lg p-6">
        <div class="flex justify-between items-center mb-4">
          <h2 class="text-xl text-light-50">New Note</h2>
          <button @click="editorVisible = false" class="btn btn-sm btn-circle">✕</button>
        </div>
        <textarea v-model="noteContent" class="textarea textarea-bordered w-full h-3/4 mb-4"></textarea>
        <div class="flex justify-end">
          <button @click="saveNote" class="btn w-full md:w-auto text-white hover:bg-secondary-600 hover:text-dark" :disabled="isNoteContentEmpty">Save Note</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { ref, onMounted, computed } from 'vue';
import services from '@/services.js';

export default {
  name: 'NoteTimeline',
  props: {
    lotId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const notes = ref([]);
    const noteContent = ref('');
    const filterText = ref('');
    const isLoading = ref(true);
    const editorVisible = ref(false);

    const fetchNotes = async () => {
      try {
        const response = await services.getNotes(props.lotId);
        notes.value = response.data.sort((a, b) => new Date(b.timeStamp) - new Date(a.timeStamp));
      } catch (error) {
        console.error('Failed to fetch notes', error);
      } finally {
        isLoading.value = false;
      }
    };

    const openEditor = () => {
      editorVisible.value = true;
    };

    const saveNote = async () => {
      if (noteContent.value.trim()) {
        await services.saveNote(props.lotId, noteContent.value.trim());
        noteContent.value = '';
        editorVisible.value = false;
        await fetchNotes();
      }
    };

    const formatTimestamp = (timestamp) => {
      const date = new Date(timestamp);
      return date.toLocaleString();
    };

    const isNoteContentEmpty = computed(() => !noteContent.value.trim());

    const filteredNotes = computed(() => {
      if (!filterText.value.trim()) {
        return notes.value;
      }
      const filter = filterText.value.toLowerCase();
      return notes.value.filter(note => note.content.toLowerCase().includes(filter));
    });

    onMounted(() => {
      fetchNotes();
    });

    return {
      notes,
      noteContent,
      filterText,
      isLoading,
      editorVisible,
      openEditor,
      saveNote,
      formatTimestamp,
      isNoteContentEmpty,
      filteredNotes,
    };
  },
};
</script>
