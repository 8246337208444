export function printQRCode(qrCodeValue, lotName) {
    const iframe = document.createElement('iframe');
    iframe.style.position = 'absolute';
    iframe.style.width = '0';
    iframe.style.height = '0';
    iframe.style.border = '0';
    document.body.appendChild(iframe);

    const doc = iframe.contentWindow.document;
    doc.open();
    doc.write(`
    <html>
      <head>
        <style>
          @media print {
            header, footer {
                display: none;
            }
            
            body {
              margin: 0;
              padding: 0;
              display: flex;
              align-items: center;
              justify-content: center;
              height: 100vh;
            }
            .print-container {
              display: flex;
              flex-direction: column;
              align-items: center;
            }
            .label {
              font-size: 1.5em;
              font-weight: bold;
              margin-bottom: 20px;
            }
          }
        </style>
        <script src="https://cdn.jsdelivr.net/npm/qrcode/build/qrcode.min.js"><\/script>
      </head>
      <body>
      <div class="print-container">
        <div style="text-transform: uppercase;" class="label" id="lotName"></div>
        <canvas class="qr-code" id="qrCode"></canvas>
      </div>
      <script>
        window.onload = function() {
          const lotNameElement = document.getElementById('lotName');
          lotNameElement.innerHTML = '${lotName}'; // Set the lotName value

          const qrCodeElement = document.getElementById('qrCode');
          QRCode.toCanvas(qrCodeElement, '${qrCodeValue}', { width: 400, height: 400 }, function(error) {
            if (error) console.error(error);
            window.print();
            setTimeout(() => {
              document.body.removeChild(iframe);
            }, 1000);
          });
        };
      <\/script>
      </body>
      </html>
  `);
    doc.close();

    iframe.onload = () => {
        iframe.contentWindow.focus();
    };
}
