<template>
  
    <h3 class="font-bold text-lg text-light-100 mb-4">Add New Location</h3>
    <form @submit.prevent="handleNewLocationSubmit">
      <div class="mb-4">
        <label for="newLocation" class="block text-sm font-medium text-light-50">New Location Name</label>
        <input
            id="newLocation"
            v-model="newLocation"
            type="text"
            class="mt-1 text-dark block w-full border-gray-300 rounded-md shadow-sm focus:ring focus:border-blue-300"
            placeholder="Enter new location name"
            @blur="v$.newLocation.$touch()"
        />
        <p v-if="v$.newLocation.$error" class="text-tertiary-500 mt-1">New location name is required.</p>
      </div>

      <div class="flex justify-end gap-2">
        <button type="button" @click="cancelNewLocation" class="btn bg-secondary hover:bg-secondary-700 text-dark mt-4">
          Cancel
        </button>
        <button type="submit" :disabled="v$.newLocation.$invalid" class="btn bg-tertiary-500 hover:bg-tertiary-700 text-dark mt-4">
          Submit
        </button>
      </div>
    </form>

</template>

<script>
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';

export default {
  data() {
    return {
      newLocation: '',
    };
  },
  setup() {
    return { v$: useVuelidate() };
  },
  validations() {
    return {
      newLocation: { required },
    };
  },
  methods: {
    cancelNewLocation() {
      this.newLocation = '';
      this.v$.$reset();
      this.$emit('cancelNewLocation');
    },
    handleNewLocationSubmit() {
      this.v$.$touch();
      if (this.v$.newLocation.$invalid) {
        return;
      }

      const newLocationData = {
        newLocation: this.newLocation,
      };

      this.$emit('submitNewLocation', newLocationData);
      this.newLocation = '';
      this.v$.$reset();
    },
  },
};
</script>
