<script lang="ts">
import { defineComponent } from 'vue';
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';

export default defineComponent({
  name: 'DeleteLotConfirmation',
  props: {
    lot: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      reason: '',
    };
  },
  setup() {
    return { v$: useVuelidate() };
  },
  validations() {
    return {
      reason: { required },
    };
  },
  methods: {
    cancelDelete() {
      this.$emit('cancel-delete');
    },
    confirmDelete() {
      this.v$.$touch();
      if (this.v$.$invalid) {
        return;
      }
      this.$emit('confirmDelete', { lot: this.lot, reason: this.reason });
    },
  },
});
</script>

<template>
  <div>
    <h3 class="font-bold text-lg">Confirmation</h3>
    <p>Are you sure you want to delete {{ lot.name }}?</p>
    <div class="mb-4">
      <label for="deleteReason" class="block text-sm font-medium">Reason for Deletion</label>
      <input
          id="deleteReason"
          v-model="reason"
          type="text"
          class="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring focus:border-blue-300"
          placeholder="Enter reason for deletion"
          @blur="v$.reason.$touch()"
      />
      <p v-if="v$.reason.$error" class="text-red-500 mt-1">Reason for deletion is required.</p>
    </div>
    <div class="modal-action">
      <button @click="confirmDelete" class="btn btn-error" :disabled="v$.$invalid">Yes, Delete</button>
      <button @click="cancelDelete" class="btn">Cancel</button>
    </div>
  </div>
</template>
