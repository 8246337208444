import axios from "axios";

const BASE_URL = "https://weed-garden.com/api";
//const BASE_URL = "http://localhost:5000/api";

const axiosInstance = axios.create({
  baseURL: BASE_URL
});

let isRefreshing = false;
let refreshTokenPromise = null;

const getJwtToken = () => localStorage.getItem('user-token');
const getRefreshToken = () => localStorage.getItem('refresh-token');
const getTokenExpiration = () => parseInt(localStorage.getItem('token-expiration'), 10);

const isTokenExpired = () => {
  const expirationTime = getTokenExpiration();
  const now = new Date().getTime() / 1000; // Convert to seconds
  const bufferTime = 5 * 60; // 5 minutes buffer

  return now > (expirationTime - bufferTime);
};

const refreshToken = async () => {
  if (!isRefreshing) {
    isRefreshing = true;
    refreshTokenPromise = axios.post(`${BASE_URL}/user/refresh-token`, {
      refreshToken: getRefreshToken(),
    })
        .then(response => {
          const { accessToken, refreshToken: newRefreshToken, accessTokenExpiresIn } = response.data.data;
          console.log(accessToken, newRefreshToken);

          localStorage.setItem('user-token', accessToken);
          localStorage.setItem('refresh-token', newRefreshToken);
          localStorage.setItem('token-expiration', Math.floor(new Date().getTime() / 1000) + accessTokenExpiresIn);

          isRefreshing = false;
          return accessToken;
        })
        .catch(error => {
          isRefreshing = false;
          localStorage.removeItem('user-token');
          localStorage.removeItem('refresh-token');
          localStorage.removeItem('token-expiration');
          localStorage.removeItem('isAuthenticated');

          alert('Your session has expired. Please log in again.');
          window.location.href = '/login';

          return Promise.reject(error);
        });
  }

  return refreshTokenPromise;
};

axiosInstance.interceptors.request.use(
    async (config) => {
      let token = getJwtToken();

      if (token && isTokenExpired()) {
        token = await refreshToken();
      }

      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }

      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
);

export const getBaseURL = () => axiosInstance.getUri();

export default {
  async generatePdf(lotId) {
    return await axiosInstance.get(`/lots/${lotId}/generate-pdf`, {
      responseType: "arraybuffer",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/pdf",
      },
    });
  },
  async getImages(lotId) {
    const response = await axiosInstance.get(`/lots/${lotId}/images`);
    return this.handleApiResponse(response);
  },
  async uploadFile(lotId, file) {
    const formData = new FormData();
    formData.append('file', file); // Append the file to the formData

    try {
      const response = await axiosInstance.post(
          `lots/${lotId}/images`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          }
      );
      return response.data;
    } catch (error) {
      console.error('Error uploading file:', error);
      throw error;
    }
  },
  async addCost(lotId, expense) {
    const response = await axiosInstance.post(`/lots/${lotId}/costs`, expense);
    return this.handleApiResponse(response);
  },
  async updateCost(lotId, expense) {
    const response = await axiosInstance.put(`/lots/${lotId}/costs/${expense.id}`, expense);
    return this.handleApiResponse(response);
  },
  async getCosts(lotId) {
    const response = await axiosInstance.get(`/lots/${lotId}/costs`);
    return this.handleApiResponse(response);
  },
  addLot(lotName, locationId) {
    const data = {
      lotName,
      locationId,
    };
    return axiosInstance.post("/lots", data).then(this.handleApiResponse);
  },
  async deleteLot(lotId, data) {
    const response = await axiosInstance.put(`/lots/${lotId}/trash`, data);
    return this.handleApiResponse(response);
  },
  async deleteImage(lotId, imageId) {
    const response = await axiosInstance.delete(`/lots/${lotId}/images/${imageId}`);
    return this.handleApiResponse(response);
  },
  getLots() {
    return axiosInstance.get("/lots").then(this.handleApiResponse);
  },
  getDeletedLots() {
    return axiosInstance.get("/lots/deleted").then(this.handleApiResponse);
  },
  getHarvestedLots() {
    return axiosInstance.get("/lots/harvested").then(this.handleApiResponse);
  },
  async getLotDetails(lotId) {
    const response = await axiosInstance.get(`/lots/${lotId}`);
    return this.handleApiResponse(response);
  },
  async updateLotDetails(lotId, lotDetails) {
    const response = await axiosInstance.put(`/lots/${lotId}`, lotDetails, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return this.handleApiResponse(response);
  },
  async addOrUpdatePlantingDetails(lotId, plantingDetails) {
    const response = await axiosInstance.put(`/lots/${lotId}/plantingdetails`, plantingDetails, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return this.handleApiResponse(response);
  },
  async getLotImages(lotId) {
    const response = await axiosInstance.get(`/lots/${lotId}/images`);
    return this.handleApiResponse(response);
  },
  async getLotImage(lotId, imageId) {
    const response = await axiosInstance.get(`/lots/${lotId}/images/${imageId}`);
    return this.handleApiResponse(response);
  },
  async updateLotImage(lotId, imageId) {
    const response = await axiosInstance.put(`/lots/${lotId}/primary-image/${imageId}`);
    return this.handleApiResponse(response);
  },
  async addLotImage(formData, guid) {
    const response = await axiosInstance.post(`/lots/${guid}/images`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return this.handleApiResponse(response);
  },
  async saveNote(lotId, content) {
    const response = await axiosInstance.post(`/lots/${lotId}/notes`, { content });
    return this.handleApiResponse(response);
  },
  async getNotes(lotId) {
    const response = await axiosInstance.get(`/lots/${lotId}/notes`);
    return this.handleApiResponse(response);
  },
  async updateNotes(lotId, notes) {
    const response = await axiosInstance.put(`/lots/${lotId}/notes`, notes, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return this.handleApiResponse(response);
  },
  async getPlantingDetails(lotId) {
    const response = await axiosInstance.get(`/lots/${lotId}/plantingdetails`);
    return this.handleApiResponse(response);
  },
  async getSourceDetails(lotId) {
    const response = await axiosInstance.get(`/lots/${lotId}/sourcedetails`);
    return this.handleApiResponse(response);
  },
  async getAuditTrail(lotId) {
    const response = await axiosInstance.get(`/lots/${lotId}/audit-trail`);
    return this.handleApiResponse(response);
  },
  async getProducts(lotId) {
    const response = await axiosInstance.get(`/lots/${lotId}/products`);
    return this.handleApiResponse(response);
  },
  async addProduct(productName, lotId) {
    const response = await axiosInstance.post(`/lots/${lotId}/products/${productName}`);
    return this.handleApiResponse(response);
  },
  async addProductAttribute(attributeName, attributeValue, productId, lotId) {
    const response = await axiosInstance.put(`/lots/${lotId}/products/${productId}`, {
      attributeName,
      attributeValue,
    });
    return this.handleApiResponse(response);
  },
  async getEvents(lotId) {
    const response = await axiosInstance.get(`/lots/${lotId}/events`);
    return this.handleApiResponse(response);
  },
  async addEvent(lotId, data) {
    const response = await axiosInstance.post(`/lots/${lotId}/events`, { data });
    return this.handleApiResponse(response);
  },
  async getCurrentStage(lotId) {
    const response = await axiosInstance.get(`/lots/${lotId}/stages/current`);
    return this.handleApiResponse(response);
  },
  async addStage(lotId, stageName) {
    const response = await axiosInstance.post(`/lots/${lotId}/stages/${stageName}`);
    return this.handleApiResponse(response);
  },
  async getLocations() {
    const response = await axiosInstance.get("/locations");
    return this.handleApiResponse(response);
  },
  async addLocation(locationName) {
    const response = await axiosInstance.post("/locations", { name: locationName });
    return this.handleApiResponse(response);
  },
  async saveLocations(locationId, layoutConfig) {
    let response;
    if (!locationId) {
      response = await axiosInstance.post("/locations/", layoutConfig);
      locationId = response.data.id;
    }
    response = await axiosInstance.post(`/locations/${locationId}`, {
      x: layoutConfig.x,
      y: layoutConfig.y,
      w: layoutConfig.w,
      h: layoutConfig.h,
    });
    return this.handleApiResponse(response);
  },
  async addNewLocation() {
    const response = await axiosInstance.post("/locations/", {});
    return this.handleApiResponse(response);
  },
  async saveLotLocation(lotId, locationId) {
    const response = await axiosInstance.post(`/lots/${lotId}/location/${locationId}`);
    return this.handleApiResponse(response);
  },
  async renameLocation(locationId, newName) {
    const response = await axiosInstance.post(`/locations/${locationId}/rename`, {
      newName: newName,
    });
    return this.handleApiResponse(response);
  },
  async updateLocationProperty(locationId, location) {
    const response = await axiosInstance.put(`/locations/${locationId}`, location);
    return this.handleApiResponse(response);
  },
  async deleteLocation(locationId) {
    const response = await axiosInstance.delete(`/locations/${locationId}`);
    return this.handleApiResponse(response);
  },
  async getStages() {
    const response = await axiosInstance.get("/stages");
    return this.handleApiResponse(response);
  },
  async saveStage(lotId, stageId) {
    const response = await axiosInstance.post(`/lots/${lotId}/stages/${stageId}`);
    return this.handleApiResponse(response);
  },
  async listSources() {
    const response = await axiosInstance.get(`/sources/`);
    return this.handleApiResponse(response);
  },
  async addSource(model) {
    const response = await axiosInstance.post(`/sources/`, model);
    return this.handleApiResponse(response);
  },
  async editSource(sourceId, model) {
    const response = await axiosInstance.post(`/sources/${sourceId}`, model);
    return this.handleApiResponse(response);
  },
  async deleteSource(sourceId) {
    const response = await axiosInstance.delete(`/sources/${sourceId}`);
    return this.handleApiResponse(response);
  },
  async updateLotSource(lotId, sourceId) {
    const response = await axiosInstance.post(`/lots/${lotId}/sources/${sourceId}`);
    return this.handleApiResponse(response);
  },
  async loginUser(model) {
    const response = await axiosInstance.post(`/user/login`, model);
    return this.handleApiResponse(response);
  },
  async registerUser(model) {
    const response = await axiosInstance.post(`/user/register`, model);
    return this.handleApiResponse(response);
  },
  async forgotPassword(model) {
    const response = await axiosInstance.post(`/user/forgot-password`, model);
    return this.handleApiResponse(response);
  },
  async resetPassword(model) {
    const response = await axiosInstance.post(`/user/reset-password`, model);
    return this.handleApiResponse(response);
  },
  async getTeam() {
    const response = await axiosInstance.get("/user/default-team");
    return this.handleApiResponse(response);
  },
  async getSensorMeasurements(locationId, startDate, endDate, filters) {
    try {
      const response = await axiosInstance.get('/locationsensormeasurement', {
        params: {
          locationId: locationId,
          startDate: startDate,
          endDate: endDate,
          filters: filters || null
        }
      });
      return response;
    } catch (error) {
      console.error('Error fetching sensor measurements:', error);
      throw error;
    }
  },

  handleApiResponse(response) {
    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error(response.data.message || "An error occurred");
    }
  }
};
