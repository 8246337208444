<script>
import SubNav from '@/components/Nav/SubNav.vue';

export default { 
  components: {
    SubNav
  } 
}
</script>

<template>
  <SubNav />
  <section class="relative py-20 transition-colors duration-500 lg:py-28 bg-gradient-to-r from-dark-800 via-primary to-dark-800 text-light-50">
    <div class="relative z-10 px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
      <div class="text-left">
        <h1 class="mb-6 text-5xl font-extrabold tracking-tight text-primary-50 sm:text-6xl">Privacy Policy</h1>
        <p class="mb-8 text-xl text-secondary-400">Last updated: August 26, 2024</p>

        <p class="mb-8 text-lg text-secondary-200">
          This Privacy Policy describes Our policies and procedures on the collection, use, and disclosure of Your information when You use the Service and tells You about Your privacy rights and how the law protects You.
        </p>

        <p class="mb-8 text-lg text-secondary-200">
          We use Your Personal data to provide and improve the Service. By using the Service, You agree to the collection and use of information in accordance with this Privacy Policy. This Privacy Policy has been created with the help of the
          <a href="https://www.termsfeed.com/privacy-policy-generator/" class="underline text-primary-50 hover:text-primary-100" target="_blank">Privacy Policy Generator</a>.
        </p>

        <h2 class="mb-6 text-4xl font-extrabold text-light-100">Interpretation and Definitions</h2>

        <h3 class="mb-6 text-2xl font-bold text-light-100">Interpretation</h3>
        <p class="mb-8 text-lg text-secondary-200">
          The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.
        </p>

        <h3 class="mb-6 text-2xl font-bold text-light-100">Definitions</h3>
        <ul class="pl-5 mb-8 space-y-4 text-lg list-disc text-secondary-200">
          <li><strong>Account:</strong> A unique account created for You to access our Service or parts of our Service.</li>
          <li><strong>Affiliate:</strong> An entity that controls, is controlled by, or is under common control with a party, where "control" means ownership of 50% or more of the shares, equity interest, or other securities entitled to vote for election of directors or other managing authority.</li>
          <li><strong>Company:</strong> (referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to CS Industries LLC, 151 8th Avenue South #113 Hopkins MN 55343.</li>
          <li><strong>Cookies:</strong> Small files placed on Your computer, mobile device, or any other device by a website, containing the details of Your browsing history on that website among its many uses.</li>
          <li><strong>Country:</strong> Refers to: Minnesota, United States.</li>
          <li><strong>Device:</strong> Any device that can access the Service, such as a computer, a cellphone, or a digital tablet.</li>
          <li><strong>Personal Data:</strong> Any information that relates to an identified or identifiable individual.</li>
          <li><strong>Service:</strong> Refers to the Website.</li>
          <li><strong>Service Provider:</strong> Any natural or legal person who processes the data on behalf of the Company. It refers to third-party companies or individuals employed by the Company to facilitate the Service, provide the Service on behalf of the Company, perform services related to the Service, or assist the Company in analyzing how the Service is used.</li>
          <li><strong>Usage Data:</strong> Data collected automatically, either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).</li>
          <li><strong>Website:</strong> Refers to Weed Garden, accessible from
            <a href="https://weed-garden.com" rel="external nofollow noopener" target="_blank" class="underline text-primary-50 hover:text-primary-100">https://weed-garden.com</a>.
          </li>
          <li><strong>You:</strong> The individual accessing or using the Service, or the company or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.</li>
        </ul>

        <h2 class="mb-6 text-4xl font-extrabold text-light-100">Collecting and Using Your Personal Data</h2>

        <h3 class="mb-6 text-2xl font-bold text-light-100">Types of Data Collected</h3>

        <h4 class="mb-4 text-xl font-bold text-light-100">Personal Data</h4>
        <p class="mb-8 text-lg text-secondary-200">
          While using Our Service, We may ask You to provide Us with certain personally identifiable information that can be used to contact or identify You. Personally identifiable information may include, but is not limited to:
        </p>
        <ul class="pl-5 mb-8 space-y-4 text-lg list-disc text-secondary-200">
          <li>Email address</li>
          <li>First name and last name</li>
          <li>Usage Data</li>
        </ul>

        <h4 class="mb-4 text-xl font-bold text-light-100">Usage Data</h4>
        <p class="mb-8 text-lg text-secondary-200">
          Usage Data is collected automatically when using the Service. It may include information such as Your Device's Internet Protocol address (e.g., IP address), browser type, browser version, the pages of our Service that You visit, the time and date of Your visit, the time spent on those pages, unique device identifiers, and other diagnostic data.
        </p>

        <h2 class="mb-6 text-4xl font-extrabold text-light-100">Contact Us</h2>
        <p class="text-lg text-secondary-200">
          If you have any questions about this Privacy Policy, please contact us at
          <a href="mailto:support@weed-garden.com" class="underline text-primary-50 hover:text-primary-100">support@weed-garden.com</a>.
        </p>
      </div>
    </div>
  </section>
</template>
