<template>
  <div class="min-h-screen p-4">
    <!-- Loading Spinner -->
    <div v-if="showLoadingSpinner" class="flex items-center justify-center h-full">
      <h1 class="text-2xl text-light-50">Loading... Please wait</h1>
    </div>

    <div v-else class="w-full">
      <!-- File Upload Dropzone -->
      <div class="flex items-center justify-center w-full mb-6">
        <label for="dropzone-file" class="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-600 border-dashed rounded-lg cursor-pointer bg-gray-700 hover:bg-gray-600 dark:hover:bg-gray-800 dark:bg-gray-900 dark:border-gray-500 dark:hover:border-gray-400">
          <div class="flex flex-col items-center justify-center pt-5 pb-6">
            <svg class="w-8 h-8 mb-4 text-gray-300" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"/>
            </svg>
            <p class="mb-2 text-sm text-gray-300"><span class="font-semibold">Click to upload</span> or drag and drop</p>
            <p class="text-xs text-gray-400">SVG, PNG, JPG or GIF (MAX. 800x400px)</p>
          </div>
          <input id="dropzone-file" type="file" @change="handleFileInput" class="hidden" multiple />
        </label>
      </div>

      <!-- Pending Files Section -->
      <div v-if="files.length > 0" class="mb-4">
        <h5 class="text-lg font-semibold text-light-50">Pending</h5>
        <div class="grid grid-cols-2 gap-4">
          <div v-for="(file, index) in files" :key="file.name" class="card bg-secondary shadow-md">
            <figure>
              <img :src="file.objectURL" :alt="file.name" class="w-24 h-auto mb-2" />
            </figure>
            <div class="card-body">
              <h2 class="card-title">{{ file.name }}</h2>
              <p>{{ formatSize(file.size) }}</p>
              <div class="badge badge-warning">Pending</div>
              <button @click="removeFile(index)" class="btn btn-sm btn-error mt-2">Remove</button>
            </div>
          </div>
        </div>
      </div>

      <!-- Completed Files Section -->
      <div v-if="uploadedFiles.length > 0" class="mb-4">
        <h5 class="text-lg font-semibold text-light-50">Completed</h5>
        <div class="grid grid-cols-2 gap-4">
          <div v-for="(file, index) in uploadedFiles" :key="file.name" class="card bg-primary shadow-md">
            <figure>
              <img :src="file.objectURL" :alt="file.name" class="w-24 h-auto mb-2" />
            </figure>
            <div class="card-body">
              <h2 class="card-title">{{ file.name }}</h2>
              <p>{{ formatSize(file.size) }}</p>
              <div class="badge badge-success">Completed</div>
              <button @click="removeUploadedFile(index)" class="btn btn-sm btn-error mt-2">Remove</button>
            </div>
          </div>
        </div>
      </div>

      <!-- Empty State -->
      <div v-if="files.length === 0 && uploadedFiles.length === 0" class="text-center py-8">
        <div class="alert alert-info shadow-lg">
          <div>
            <i class="pi pi-cloud-upload text-7xl mr-2"></i>
            <span>Drag and drop files here to upload</span>
          </div>
        </div>
      </div>

      <!-- Image Gallery -->
      <div :class="['grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4', viewMode]">
        <div v-for="(item, index) in visibleImages" :key="index" class="relative" @click="openFullSizeImage(item)" @contextmenu.prevent="onImageRightClick($event, item)">
          <img :src="viewMode === 'gallery' ? item.galleryFilePath : item.thumbnailFilePath" class="w-full h-auto rounded-lg shadow-md cursor-pointer" />
          <div class="absolute top-2 right-2">
            <button @click="deleteImage(item)" class="btn btn-sm btn-error">Delete</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import apiService from '@/services.js'; // Assuming you have an apiService for handling uploads

export default {
  props: [
    'lotId'  
  ],
  data() {
    return {
      showLoadingSpinner: false,
      files: [],
      uploadedFiles: [],
      visibleImages: [],
      viewMode: 'gallery',
    };
  },
  async mounted() {
    const response = await apiService.getImages(this.lotId);
    this.visibleImages = response.data;
  },
  methods: {
    async handleFileInput(event) {
      const selectedFiles = Array.from(event.target.files);

      // Add the files to the pending list
      this.files = selectedFiles.map((file) => ({
        name: file.name,
        size: file.size,
        objectURL: URL.createObjectURL(file),
      }));

      // Automatically upload the files after selection
      for (const file of selectedFiles) {
        await this.uploadFile(file);
      }
    },

    async uploadFile(file) {
      try {
        this.showLoadingSpinner = true;

        const response = await apiService.uploadFile(this.lotId, file);

        this.uploadedFiles.push({
          name: file.name,
          size: file.size,
          objectURL: URL.createObjectURL(file),
        });

        this.files = this.files.filter((f) => f.name !== file.name);
      } catch (error) {
        console.error('File upload failed:', error);
      } finally {
        this.showLoadingSpinner = false;
      }
    },

    formatSize(size) {
      const kb = size / 1024;
      return kb > 1024 ? `${(kb / 1024).toFixed(2)} MB` : `${kb.toFixed(2)} KB`;
    },

    removeFile(index) {
      this.files.splice(index, 1);
    },

    removeUploadedFile(index) {
      this.uploadedFiles.splice(index, 1);
    },

    openFullSizeImage(item) {
      // Logic to open the full-size image
    },

    onImageRightClick(event, item) {
      // Logic for right-click context menu
    },

    deleteImage(item) {
      this.visibleImages = this.visibleImages.filter((image) => image !== item);
    },
  },
};
</script>
