<template>
  <div class="contact-page">
    <div class="form-container">
      <form @submit.prevent="submitForm">
        <h2>Contact Us</h2>
        <div class="field">
          <label for="name">Name</label>
          <div class="input-wrapper">
            <i class="pi pi-user input-icon"></i>
            <InputText id="name" v-model="form.name" required class="w-full p-inputtext-dark" placeholder="Your Name" />
          </div>
        </div>
        <div class="field">
          <label for="email">Email</label>
          <div class="input-wrapper">
            <i class="pi pi-envelope input-icon"></i>
            <InputText id="email" type="email" v-model="form.email" required class="w-full p-inputtext-dark" placeholder="Your Email" />
          </div>
        </div>
        <div class="field">
          <label for="message">Message</label>
          <Textarea id="message" v-model="form.message" required rows="5" class="w-full p-inputtext-dark" placeholder="Your Message" />
        </div>
        <div class="button-group">
          <Button type="submit" label="Send Message" icon="pi pi-send" class="p-button-info p-button-raised" />
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        name: '',
        email: '',
        message: ''
      }
    };
  },
  methods: {
    submitForm() {
      if (this.form.name && this.form.email && this.form.message) {
        this.$toast.add({
          severity: 'success',
          summary: 'Success',
          detail: 'Your message has been sent.',
          life: 3000
        });

        // Reset form fields
        this.form.name = '';
        this.form.email = '';
        this.form.message = '';
      } else {
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Please fill in all fields.',
          life: 3000
        });
      }
    }
  }
};
</script>

<style scoped>
.contact-page {
  background-color: rgba(44, 62, 80, 0.4);
  display: flex;
  justify-content: center;
  height: 100vh;
}

.form-container {
  padding: 30px;
  border-radius: 8px;
  width: 100%;
  max-width: 600px;
  margin-top: 60px;
}

h2 {
  margin-bottom: 24px;
  font-size: 1.5rem;
  font-weight: bold;
  color: #e2e8f0;
  text-align: center;
  border-bottom: 2px solid #4b5563;
  padding-bottom: 10px;
}

.field {
  margin-bottom: 20px;
}

.field label {
  display: block;
  margin-bottom: 8px;
  font-weight: bold;
  color: #cbd5e1;
  font-size: 0.95rem;
}

.input-wrapper {
  position: relative;
}

.input-icon {
  position: absolute;
  top: 50%;
  left: 12px;
  transform: translateY(-50%);
  color: #64748b;
}

.p-inputtext-dark {
  background-color: #334155;
  color: #ffffff;
  border: 1px solid #475569;
  border-radius: 6px;
  padding-left: 40px;
  transition: border-color 0.3s, background-color 0.3s;
}

.p-inputtext-dark:focus {
  border-color: #3b82f6;
  background-color: #1e293b;
  outline: none;
  box-shadow: none;
}

.p-inputtext-dark::placeholder {
  color: #94a3b8;
}

.button-group {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

textarea.p-inputtext-dark {
  resize: vertical;
  padding: 10px;
  background-color: #334155;
  color: #ffffff;
  border: 1px solid #475569;
}

textarea.p-inputtext-dark:focus {
  background-color: #1e293b;
  border-color: #3b82f6;
}

textarea.p-inputtext-dark::placeholder {
  color: #94a3b8;
}
</style>
