<template>
    <h3 class="font-bold text-lg text-light-100 mb-4">Add New Lot</h3>
    <form @submit.prevent="handleSubmit">
      <div class="mb-4">
        <label for="lotName" class="block text-sm font-medium text-light-50">Lot Name</label>
        <input
            id="lotName"
            v-model="lotName"
            type="text"
            class="mt-1 text-dark block w-full border-gray-300 rounded-md shadow-sm focus:ring focus:border-blue-300"
            placeholder="Enter lot name"
            @blur="v$.lotName.$touch()"
        />
        <p v-if="v$.lotName.$error" class="text-tertiary-500 mt-1">Lot name is required.</p>
      </div>

      <div class="mb-4">
        <label for="location" class="block text-sm font-medium text-light-50">Select Location</label>
        <select
            id="location"
            v-model="selectedLocation"
            class="mt-1 text-dark block w-full border-gray-300 rounded-md shadow-sm focus:ring focus:border-tertiary-300"
            @blur="v$.selectedLocation.$touch()"
        >
          <option value="" disabled>Select a location</option>
          <option v-for="location in locationOptions" :key="location.id" :value="location.id">
            {{ location.name }}
          </option>
          <option value="new">Add New Location</option>
        </select>
        <p v-if="v$.selectedLocation.$error" class="text-tertiary-500 mt-1">Please select a location.</p>
      </div>

      <div v-if="selectedLocation === 'new'" class="mb-4">
        <label for="newLocation" class="block text-sm font-medium text-light-50">New Location Name</label>
        <input
            id="newLocation"
            v-model="newLocation"
            type="text"
            class="mt-1 text-dark block w-full border-gray-300 rounded-md shadow-sm focus:ring focus:border-blue-300"
            placeholder="Enter new location name"
            @blur="v$.newLocation.$touch()"
        />
        <p v-if="v$.newLocation.$error" class="text-tertiary-500 mt-1">New location name is required.</p>
      </div>

      <div class="flex justify-end gap-2">
        <button type="button" @click="cancelNewLotSubmit" class="btn bg-secondary hover:bg-secondary-700 text-dark mt-4">
          Cancel
        </button>
        <button type="submit" :disabled="v$.$invalid || (selectedLocation === 'new' && !newLocation)" class="btn bg-tertiary-500 hover:bg-tertiary-700 text-dark mt-4">
          Submit
        </button>
      </div>
    </form>
</template>

<script>
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';

export default {
  data() {
    return {
      lotName: '',
      selectedLocation: null,
      newLocation: '',
      locationOptions: [],
    };
  },
  props: {
    locations: {
      type: Array,
      required: true,
    },
  },
  setup() {
    return { v$: useVuelidate() };
  },
  validations() {
    return {
      lotName: { required },
      selectedLocation: { required },
      newLocation: {
        required: () => this.selectedLocation === 'new',
      },
    };
  },
  mounted() {
    this.locationOptions = [...this.locations];
  },
  methods: {
    resetForm() {
      this.lotName = '';
      this.newLocation = '';
      this.selectedLocation = null;
      this.v$.$reset();
    },
    cancelNewLotSubmit() {
      this.resetForm();
      this.$emit('cancelNewLot');
    },
    handleSubmit() {
      this.v$.$touch();
      if (this.v$.$invalid || (this.selectedLocation === 'new' && !this.newLocation)) {
        return;
      }

      const formData = {
        lotName: this.lotName,
        selectedLocation: this.selectedLocation,
        newLocation: this.selectedLocation === 'new' ? this.newLocation : null,
      };

      this.$emit('submit', formData);
      this.resetForm();
    },
  },
};
</script>
