<template>
  <div id="GrowExpenses" class="h-screen p-4 bg-primary-100">
    <div class="bg-primary-200 p-4 rounded-lg shadow-md">
      <h2 class="text-2xl text-light-50 font-semibold mb-4">Grow Expenses</h2>
      <div v-if="expenses.length > 0" class="mt-4">
        <div class="overflow-x-auto">
          <table class="table table-compact w-full">
            <thead class="bg-primary-700 text-light-50">
            <tr>
              <th class="text-left">Purchase Date</th>
              <th class="text-left">Supplier</th>
              <th class="text-left">Description</th>
              <th class="text-left">Total Cost</th>
              <th class="text-left">Actions</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="expense in expenses" :key="expense.id" class="hover:bg-primary-600 transition">
              <td>
                <input type="date" v-model="expense.purchaseDate" @blur="updateExpense(expense)" class="input input-bordered w-full"/>
              </td>
              <td>
                <input type="text" v-model="expense.supplier" @blur="updateExpense(expense)" class="input input-bordered w-full"/>
              </td>
              <td>
                <input type="text" v-model="expense.description" @blur="updateExpense(expense)" class="input input-bordered w-full"/>
              </td>
              <td>
                <input type="number" v-model="expense.totalCost" @blur="updateExpense(expense)" class="input input-bordered w-full" />
              </td>
              <td class="text-right">
                <button @click="deleteExpense(expense)" class="btn btn-sm btn-error">
                  <i class="pi pi-trash"></i>
                </button>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>

      <!-- Empty State -->
      <div v-else class="text-center text-light-50 py-4">
        No costs found.
      </div>
    </div>
  </div>
</template>

<script>
import services from "@/services.js";
import AddExpense from "@/components/AddExpense.vue"; // Assuming this is a custom component

export default {
  components: {
    AddExpense
  },
  props: ['lotId'],
  data() {
    return {
      expenses: [],
    };
  },
  computed: {
    totalCost() {
      return this.expenses.reduce((sum, expense) => {
        if (expense.totalCost !== undefined && expense.totalCost !== null) {
          return sum + expense.totalCost;
        }
        return sum;
      }, 0);
    }
  },
  async mounted() {
    await this.loadData();
  },
  methods: {
    async loadData() {
      const response = await services.getCosts(this.lotId);
      this.expenses = response.data.map(expense => ({
        ...expense,
        purchaseDate: expense.purchaseDate ? new Date(expense.purchaseDate).toISOString().slice(0, 10) : null
      }));
    },
    async updateExpense(expense) {
      await services.updateCost(this.lotId, expense);
    },
    async deleteExpense(expense) {
      await services.updateCost(this.lotId, { ...expense, isDeleted: true });
      await this.loadData();
    },
    async addNewExpense(expense) {
      await services.addCost(this.lotId, expense);
      await this.loadData();
    }
  }
};
</script>
