<script>
import { defineComponent } from 'vue';
import services from "@/services";

export default defineComponent({
  name: "TrashedLotsPage",
  data() {
    return {
      lots: []
    }
  },
  async mounted() {
    try {
      const response = await services.getDeletedLots();
      console.log(response.data);
      this.lots = response.data
          .map(item => {
            return {
              id: item.id,
              name: item.name,
              strain: item.strain,
              plantingDate: item.plantingDate,
              trashDate: item.trashDate,
              trashReason: item.trashReason,
            }
          });
    } catch (error) {
      console.error('Error fetching lots:', error);
    }
  },
  methods: {

  }
});
</script>

<template>
  <div id="LotTrashPage" class=" min-h-screen relative bg-gradient-to-r from-dark-800 via-primary to-dark-800 text-light-50 transition-colors duration-500">
    <div class="flex-grow p-6 shadow-lg flex flex-col">
      <h1 class="text-2xl font-bold mb-4 text-light">Trashed Lots / Dead Plants</h1>
      <p class="text-lg mb-4">Lots in this location can no longer be edited</p>

      <div class="overflow-x-auto">
        <!-- Table for larger screens -->
        <table class="table w-full">
          <thead class="text-gray-100">
          <tr>
            <th class="p-3">Lot ID</th>
            <th class="p-3">Name</th>
            <th class="p-3">Strain</th>
            <th class="p-3">Planting Date</th>
            <th class="p-3">Trash Date</th>
            <th class="p-3">Trash Reason</th>
          </tr>
          </thead>
          <tbody class="text-gray-100">
          <tr v-for="lot in lots" :key="lot.id" class="hover:bg-primary-500 transition">
            <td class="p-3">{{ lot.id }}</td>
            <td class="p-3">{{ lot.name }}</td>
            <td class="p-3">{{ lot.strain }}</td>
            <td class="p-3">{{ lot.plantingDate }}</td>
            <td class="p-3">{{ lot.trashDate }}</td>
            <td class="p-3">{{ lot.trashReason }}</td>
          </tr>
          <tr v-if="lots.length === 0">
            <td colspan="6" class="text-center p-3">No trashed lots available.</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
