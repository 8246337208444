<template>
  <div v-if="isVisible" class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
    <div class="bg-white p-6 rounded-lg shadow-md w-11/12 max-w-md">
      <h3 class="text-lg font-semibold mb-4">{{ header }}</h3>
      <p class="mb-6">{{ message }}</p>
      <div class="flex justify-end space-x-3">
        <button @click="reject" class="btn btn-outline btn-secondary">Cancel</button>
        <button @click="accept" class="btn btn-outline btn-primary">Yes</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    message: String,
    header: String,
  },
  data() {
    return {
      isVisible: true,
    };
  },
  methods: {
    accept() {
      this.isVisible = false;
      this.$emit('accept');
    },
    reject() {
      this.isVisible = false;
      this.$emit('reject');
    },
  },
};
</script>

<style scoped>
/* Add any specific styles for the modal here */
</style>
