<template>
  <div ref="turnstileContainer"></div>
</template>

<script>
export default {
  props: {
    siteKey: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      widgetId: null,
    };
  },
  mounted() {
    this.renderTurnstile();
  },
  methods: {
    renderTurnstile() {
      if (window.turnstile) {
        this.widgetId = window.turnstile.render(this.$refs.turnstileContainer, {
          sitekey: this.siteKey,
          callback: this.onSuccess,
          'error-callback': this.onError,
        });
      } else {
        // Retry rendering after a delay if Turnstile is not yet loaded
        setTimeout(this.renderTurnstile, 100);
      }
    },
    onSuccess(token) {
      this.$emit('success', token);
    },
    onError() {
      this.$emit('error');
    },
    reset() {
      if (this.widgetId !== null && window.turnstile) {
        window.turnstile.reset(this.widgetId);
      }
    },
  },
};
</script>
