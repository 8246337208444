<script lang="ts">
import { defineComponent } from 'vue';
import LotDetailPage from "@/views/App/LotDetailPage.vue";

export default defineComponent({
  name: "LotDetailPageDirect",
  components: { LotDetailPage },
  data() {
    return {
      lotId: null
    };
  },
  mounted() {
    this.lotId = this.$route.params.lotid;
    console.log(this.lotId);
  }
});
</script>

<template>
  <div id="LotDetailPageDirect">
    <LotDetailPage v-if="lotId" :lot-id="lotId" :selected-tab-index="0"/>  
  </div>
  
</template>

<style scoped>
#LotDetailPageDirect {
  padding: 25px;
}
</style>
