<template>
  <section class="relative min-h-screen py-20 lg:py-28 bg-gradient-to-r from-dark-800 via-primary to-dark-800 text-light-50 transition-colors duration-500">
  <div class="flex flex-col justify-center items-center  p-6">
    <main class="w-full max-w-lg p-8 rounded-lg">
      <h1 id="page-title" class="text-4xl font-bold mb-6 text-light-50 text-center">
        {{ pageTitle }}
      </h1>

      <form
          @submit.prevent="isRegistering ? handleRegistration() : isForgotPassword ? handleForgotPassword() : handleSubmit()"
          class="space-y-6"
          :aria-labelledby="formLabelId"
      >
        <h2 :id="formLabelId" class="text-xl text-light-50 font-semibold mb-4 text-center">{{ formLabel }}</h2>

        <!-- Name Field (Registration Only) -->
        <div v-if="isRegistering" class="form-control">
          <label class="label" for="registration-name">
            <span class="label-text text-light-50">Name</span>
          </label>
          <input
              id="registration-name"
              v-model="registrationForm.name"
              type="text"
              placeholder="Enter your name"
              class="input input-bordered w-full text-dark-900 bg-light-50"
              autocomplete="name"
              required
              aria-required="true"
          />
          <span v-if="errors.name" class="text-red-500 text-sm" role="alert">{{ errors.name }}</span>
        </div>

        <!-- Email Field -->
        <div v-if="isRegistering || (!isRegistering && !isForgotPassword)" class="form-control">
          <label class="label" :for="emailId">
            <span class="label-text text-light-50">Email</span>
          </label>
          <input
              :id="emailId"
              v-model="emailModel"
              type="email"
              placeholder="Enter your email"
              class="input input-bordered w-full text-dark-900 bg-dark-200"
              autocomplete="email"
              required
              aria-required="true"
          />
          <span v-if="errors.email" class="text-red-500 text-sm" role="alert">{{ errors.email }}</span>
        </div>

        <!-- Password Field -->
        <div v-if="isRegistering || (!isRegistering && !isForgotPassword)" class="form-control">
          <label class="label" :for="passwordId">
            <span class="label-text text-light-50">Password</span>
          </label>
          <input
              :id="passwordId"
              v-model="passwordModel"
              type="password"
              placeholder="Enter your password"
              class="input input-bordered w-full text-dark-900 bg-dark-200"
              autocomplete="current-password"
              required
              aria-required="true"
          />
          <span v-if="errors.password" class="text-red-500 text-sm" role="alert">{{ errors.password }}</span>
        </div>

        <!-- Forgot Password Email Field -->
        <div v-if="isForgotPassword" class="form-control">
          <label class="label" for="forgot-password-email">
            <span class="label-text text-light-50">Email</span>
          </label>
          <input
              id="forgot-password-email"
              v-model="forgotPasswordEmail"
              type="email"
              placeholder="Enter your email"
              class="input input-bordered w-full text-dark-900 bg-dark-200"
              autocomplete="email"
              required
              aria-required="true"
          />
          <span v-if="forgotPasswordErrors.email" class="text-red-500 text-sm" role="alert">{{ forgotPasswordErrors.email }}</span>
        </div>

        <!-- Captcha (Optional) -->
        <div class="mb-4">
          <Turnstile
              ref="turnstileRef"
              site-key="0x4AAAAAAAVtMvcGcbAP_EJ7"
              @success="onCaptchaSuccess"
              @error="onCaptchaError"
              aria-label="CAPTCHA verification"
          />
        </div>

        <!-- Submit Button -->
        <button type="submit" class="btn w-full p-3 bg-tertiary-200 text-dark hover:bg-tertiary-600 transition-all rounded-lg">
          {{ submitButtonText }}
        </button>

        <!-- Additional Actions for Login -->
        <div class="text-center space-y-4" v-if="!isRegistering && !isForgotPassword">
          <button @click="showForgotPasswordTab" class="btn btn-link text-light hover:underline">Forgot Password?</button>
        </div>

        <div class="text-center" v-if="isForgotPassword">
          <button @click="goToLogin" class="btn btn-link text-light hover:underline">Back to Login</button>
        </div>
      </form>
    </main>
  </div>
  </section>
</template>

<script>
import Turnstile from "@/views/User/Turnstile.vue";
import services from "@/services.js";

export default {
  components: {
    Turnstile
  },
  data() {
    return {
      pageTitle: 'Welcome to Weed Garden',
      isRegistering: false,
      isForgotPassword: false,
      loginForm: {
        email: '',
        password: '',
      },
      registrationForm: {
        name: '',
        email: '',
        password: '',
      },
      forgotPasswordEmail: '',
      forgotPasswordErrors: {
        email: ''
      },
      errors: {
        name: '',
        email: '',
        password: ''
      }
    };
  },
  computed: {
    formLabelId() {
      return this.isRegistering ? 'registration-form-label' :
          this.isForgotPassword ? 'forgot-password-form-label' :
              'login-form-label';
    },
    formLabel() {
      return this.isRegistering ? 'Registration Form' :
          this.isForgotPassword ? 'Forgot Password Form' :
              'Login Form';
    },
    submitButtonText() {
      return this.isRegistering ? 'Register' :
          this.isForgotPassword ? 'Reset Password' :
              'Log In';
    },
    emailModel: {
      get() {
        return this.isRegistering ? this.registrationForm.email : this.loginForm.email;
      },
      set(value) {
        if (this.isRegistering) {
          this.registrationForm.email = value;
        } else {
          this.loginForm.email = value;
        }
      }
    },
    passwordModel: {
      get() {
        return this.isRegistering ? this.registrationForm.password : this.loginForm.password;
      },
      set(value) {
        if (this.isRegistering) {
          this.registrationForm.password = value;
        } else {
          this.loginForm.password = value;
        }
      }
    },
    emailId() {
      return this.isRegistering ? 'registration-email' : 'login-email';
    },
    passwordId() {
      return this.isRegistering ? 'registration-password' : 'login-password';
    }
  },
  methods: {
    goToLogin() {
      this.isForgotPassword = false;
      this.isRegistering = false;
    },
    showForgotPasswordTab() {
      this.isForgotPassword = true;
    },
    async handleSubmit() {
      try {
        const response = await services.loginUser({
          email: this.loginForm.email,
          password: this.loginForm.password,
          turnstileToken: this.captchaToken
        });

        if (response && response.data) {
          const {accessToken, refreshToken, accessTokenExpiresIn} = response.data;

          localStorage.setItem("user-token", accessToken);
          localStorage.setItem("refresh-token", refreshToken);
          localStorage.setItem("token-expiration", Math.floor(new Date().getTime() / 1000) + accessTokenExpiresIn);

          this.$root.isAuthenticated = true;
          
          this.$router.push("/app/lot-management");
          
        }
      } catch (error) {
        const errorMessage = error.response && error.response.data ? error.response.data : 'Error logging in';
        this.$toast.add({severity: 'error', summary: 'Error', detail: errorMessage, life: 3000});
      }
    }
  }
};
</script>

<style scoped>
/* You can add specific styling overrides here if necessary */
</style>
