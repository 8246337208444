<script lang="ts">
import { defineComponent, nextTick } from 'vue';
import services from '@/services.js';
import SourceDetailsComponent from "@/components/SourceDetailsComponent.vue";

export default defineComponent({
  name: "LotDetailsComponent",
  components: { SourceDetailsComponent },
  props: {
    lotId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      lotDetails: {
        id: "",
        createdOn: new Date(),
        name: "",
        strain: "",
        numberOfPlants: null,
        description: "",
        slug: "",
        imageId: null,
        imageFileName: "",
        imageFilePath: "",
        daysToMaturity: "",
        seedType: "",
        lineage: "",
        flowerType: "",
        aroma: "",
        plantingDate: null,
        method: "",
        soilType: "",
        soilAmendments: "",
        irrigationMethod: "",
        spacing: null,
        rowOrientation: null,
        location: {
          id: null,  // Initialize with null
          name: ""
        },
        stage: {
          id: null,  // Initialize with null
          name: ""
        }
      },
      originalLotDetails: {},  // Store original values for comparison
      plantingDate: null as Date | null,
      hasFetchedData: false,
      locations: [],  // Populate this with API data
      stages: []      // Populate this with API data
    };
  },
  async mounted() {
    await this.fetchLotDetails();
    await this.fetchLocations();  // Fetch available locations
    await this.fetchStages();     // Fetch available stages
    await nextTick();
    this.hasFetchedData = true;
  },
  methods: {
    // Fetch lot details and map locationId/stage
    async fetchLotDetails() {
      try {
        const response = await services.getLotDetails(this.lotId);
        this.lotDetails = { ...response.data };
        this.originalLotDetails = { ...response.data };  // Store original values for comparison
        if (response.data.plantingDate) {
          this.plantingDate = new Date(response.data.plantingDate);
        } else {
          this.plantingDate = null;
        }
      } catch (error) {
        console.error("Error fetching lot details:", error);
        this.toast.add({
          severity: 'error',
          summary: 'Fetch Error',
          detail: 'Failed to fetch lot details',
          life: 3000
        });
      }
    },

    // Fetch locations for the dropdown
    async fetchLocations() {
      try {
        const response = await services.getLocations();  // Assuming this returns a list of locations
        this.locations = response.data;
      } catch (error) {
        console.error("Error fetching locations:", error);
        this.toast.add({
          severity: 'error',
          summary: 'Fetch Error',
          detail: 'Failed to fetch locations',
          life: 3000
        });
      }
    },

    // Fetch stages for the dropdown
    async fetchStages() {
      try {
        const response = await services.getStages();  // Assuming this returns a list of stages
        this.stages = response.data;
      } catch (error) {
        console.error("Error fetching stages:", error);
        this.toast.add({
          severity: 'error',
          summary: 'Fetch Error',
          detail: 'Failed to fetch stages',
          life: 3000
        });
      }
    },

    // Update only the changed field
    async updateLotDetails(changedField: Record<string, any>) {
      const fieldKey = Object.keys(changedField)[0];

      // Only update if the field has actually changed
      if (this.originalLotDetails[fieldKey] !== changedField[fieldKey]) {
        try {
          await services.updateLotDetails(this.lotDetails.id, changedField);

          // Update original values after successful update
          this.originalLotDetails[fieldKey] = changedField[fieldKey];

          this.toast.add({
            severity: 'success',
            summary: 'Update',
            detail: 'Lot updated successfully',
            life: 3000
          });
        } catch (ex) {
          this.toast.add({
            severity: 'warn',
            summary: 'Update Failed',
            detail: 'Failed to update lot details',
            life: 3000
          });
          console.warn("Error updating lot details:", ex);
        }
      }
    },

    // Update planting date separately
    updatePlantingDate(newDate: Date) {
      if (newDate.toISOString() !== this.originalLotDetails.plantingDate) {
        this.plantingDate = newDate;
        this.updateLotDetails({ plantingDate: newDate.toISOString() });
      }
    }
  }
});
</script>

<template>
  <div class="p-6">
    <!-- Lot Details Fieldset -->
    <fieldset class="border border-gray-300 rounded-md p-4 mb-6 bg-dark-100 text-light-100">
      <legend class="text-lg font-bold">Lot Details</legend>
      <div class="grid grid-cols-1 md:grid-cols-2 gap-6 pt-4">
        <!-- Lot ID (Read-Only) -->
        <div>
          <label for="id" class="block font-semibold mb-2">Lot ID</label>
          <input id="id" v-model="lotDetails.id" class="input input-bordered w-full bg-dark-200 text-light-100" readonly />
        </div>

        <!-- Lot Name -->
        <div>
          <label for="productName" class="block font-semibold mb-2">Lot Name</label>
          <input id="productName" v-model="lotDetails.name" @blur="updateLotDetails({ name: lotDetails.name })" class="input input-bordered w-full bg-dark-200 text-light-100" />
        </div>

        <!-- Strain -->
        <div>
          <label for="lotDetails.strain" class="block font-semibold mb-2">Strain</label>
          <input id="lotDetails.strain" v-model="lotDetails.strain" @blur="updateLotDetails({ strain: lotDetails.strain })" class="input input-bordered w-full bg-dark-200 text-light-100" />
        </div>

        <!-- Location Dropdown (Populated from locations API) -->
        <div>
          <label class="block font-semibold mb-2">Location</label>
          <select v-model="lotDetails.location.id" @change="updateLotDetails({ 'location.id': lotDetails.location.id })" class="select block font-semibold mb-2 w-full bg-dark-200 text-light-100">
            <option v-for="location in locations" :key="location.id" :value="location.id">
              {{ location.name }}
            </option>
          </select>
        </div>

        <!-- Stage Dropdown -->
        <div>
          <label class="block font-semibold mb-2">Stage</label>
          <select v-model="lotDetails.stage.id" @change="updateLotDetails({ 'stage.id': lotDetails.stage.id })" class="select block font-semibold mb-2 w-full bg-dark-200 text-light-100">
            <option v-for="stage in stages" :key="stage.id" :value="stage.id">
              {{ stage.name }}
            </option>
          </select>
        </div>

        <!-- Number of Plants -->
        <div>
          <label for="lotDetails.numberOfPlants" class="block font-semibold mb-2">Number of Plants</label>
          <input id="lotDetails.numberOfPlants" v-model.number="lotDetails.numberOfPlants" @blur="updateLotDetails({ numberOfPlants: lotDetails.numberOfPlants })" class="input input-bordered w-full bg-dark-200 text-light-100" />
        </div>

        <!-- Seed Type -->
        <div>
          <label for="lotDetails.seedType" class="block font-semibold mb-2">Seed Type</label>
          <input id="lotDetails.seedType" v-model="lotDetails.seedType" @blur="updateLotDetails({ seedType: lotDetails.seedType })" class="input input-bordered w-full bg-dark-200 text-light-100" />
        </div>

        <!-- Lineage -->
        <div>
          <label for="lotDetails.lineage" class="block font-semibold mb-2">Lineage</label>
          <input id="lotDetails.lineage" v-model="lotDetails.lineage" @blur="updateLotDetails({ lineage: lotDetails.lineage })" class="input input-bordered w-full bg-dark-200 text-light-100" />
        </div>

        <!-- Days to Maturity -->
        <div>
          <label for="lotDetails.daysToMaturity" class="block font-semibold mb-2">Days To Maturity</label>
          <input id="lotDetails.daysToMaturity" v-model="lotDetails.daysToMaturity" @blur="updateLotDetails({ daysToMaturity: lotDetails.daysToMaturity })" class="input input-bordered w-full bg-dark-200 text-light-100" />
        </div>

        <!-- Flower Type -->
        <div>
          <label for="lotDetails.flowerType" class="block font-semibold mb-2">Indica / Sativa</label>
          <input id="lotDetails.flowerType" v-model="lotDetails.flowerType" @blur="updateLotDetails({ flowerType: lotDetails.flowerType })" class="input input-bordered w-full bg-dark-200 text-light-100" />
        </div>

        <!-- Potency -->
        <div>
          <label for="lotDetails.potency" class="block font-semibold mb-2">Potency</label>
          <input id="lotDetails.potency" v-model="lotDetails.potency" @blur="updateLotDetails({ potency: lotDetails.potency })" class="input input-bordered w-full bg-dark-200 text-light-100" />
        </div>

        <!-- Aroma -->
        <div>
          <label for="lotDetails.aroma" class="block font-semibold mb-2">Aroma</label>
          <input id="lotDetails.aroma" v-model="lotDetails.aroma" @blur="updateLotDetails({ aroma: lotDetails.aroma })" class="input input-bordered w-full bg-dark-200 text-light-100" />
        </div>

        <!-- Lot Description -->
        <div class="col-span-2">
          <label for="lotDetails.description" class="block font-semibold mb-2">Lot Description</label>
          <input id="lotDetails.description" v-model="lotDetails.description" @blur="updateLotDetails({ description: lotDetails.description })" class="input input-bordered w-full bg-dark-200 text-light-100" />
        </div>
      </div>
    </fieldset>

    <!-- Planting Details Fieldset -->
    <fieldset class="border border-gray-300 rounded-md p-4 mb-6 bg-dark-100 text-light-100">
      <legend class="text-lg font-bold">Planting Details</legend>
      <div class="grid grid-cols-1 md:grid-cols-2 gap-6 pt-4">
        <!-- Planting Date -->
        <div>
          <label for="plantingDate" class="block font-semibold mb-2">Planting Date</label>
          <input type="date" id="plantingDate" v-model="plantingDate" @change="updatePlantingDate" class="input input-bordered w-full bg-dark-200 text-light-100" />
        </div>

        <!-- Method -->
        <div>
          <label for="method" class="block font-semibold mb-2">Method</label>
          <input id="method" v-model="lotDetails.method" @blur="updateLotDetails({ method: lotDetails.method })" class="input input-bordered w-full bg-dark-200 text-light-100" />
        </div>

        <!-- Soil Type -->
        <div>
          <label for="soilType" class="block font-semibold mb-2">Soil Type</label>
          <input id="soilType" v-model="lotDetails.soilType" @blur="updateLotDetails({ soilType: lotDetails.soilType })" class="input input-bordered w-full bg-dark-200 text-light-100" />
        </div>

        <!-- Soil Amendments -->
        <div>
          <label for="soilAmendments" class="block font-semibold mb-2">Soil Amendments</label>
          <input id="soilAmendments" v-model="lotDetails.soilAmendments" @blur="updateLotDetails({ soilAmendments: lotDetails.soilAmendments })" class="input input-bordered w-full bg-dark-200 text-light-100" />
        </div>

        <!-- Irrigation Method -->
        <div>
          <label for="irrigationMethod" class="block font-semibold mb-2">Irrigation Method</label>
          <input id="irrigationMethod" v-model="lotDetails.irrigationMethod" @blur="updateLotDetails({ irrigationMethod: lotDetails.irrigationMethod })" class="input input-bordered w-full bg-dark-200 text-light-100" />
        </div>
      </div>
    </fieldset>

    <!-- Source Details Component -->
    <SourceDetailsComponent :lot-id="lotId" />
  </div>
</template>
