<script>
import { defineComponent, ref } from 'vue';
import services from "@/services";

export default defineComponent({
  name: "HarvestProductPage",
  data() {
    return {
      harvestStageId: 'b28a05f9-7699-4c64-9c6a-80b70620ce29',
      lots: [],
      selectedLot: null, // Holds the selected lot for the sidebar
      sidebarVisible: false
    };
  },
  async mounted() {
    try {
      const response = await services.getHarvestedLots();
      this.lots = response.data
          .filter(item => item.stage && item.stage.id === this.harvestStageId)
          .map(item => {
            return {
              id: item.id,
              name: item.name,
              strain: item.strain,
              tested: true,
              numberOfPlants: item.numberOfPlants,
              wetWeight: item.wetWeight,
              dryWeight: item.dryWeight,
              description: item.description
            };
          });
    } catch (error) {
      console.error('Error fetching lots:', error);
    }
  },
  methods: {
    getRowData(data) {
      return data.tested ? 'Yes' : 'No';
    },
    openSidebar(lot) {
      this.selectedLot = lot;
      this.sidebarVisible = true;
    },
    closeSidebar() {
      this.sidebarVisible = false;
      this.selectedLot = null;
    },
    createProduct(lotId) {
      console.log(`Create product for lot ${lotId}`);
    }
  }
});
</script>

<template>
  <div id="LotHarvestListPage" class="min-h-screen  relative bg-gradient-to-r from-dark-800 via-primary to-dark-800 text-light-50 transition-colors duration-500">
    <div class="flex-grow p-6 shadow-lg flex flex-col">
      <h1 class="text-2xl font-bold mb-4 text-light">Harvesting Page, Where Lot turns to product</h1>

      <div class="overflow-x-auto">
        <!-- Table for larger screens -->
        <table class="table w-full">
          <thead class="text-gray-100">
          <tr>
            <th class="p-3">Lot Id</th>
            <th class="p-3">Name</th>
            <th class="p-3">Strain</th>
            <th class="p-3 text-right">Actions</th>
          </tr>
          </thead>
          <tbody class="text-gray-100">
          <tr v-for="lot in lots" :key="lot.id" class="hover:bg-primary-500 transition">
            <td class="p-3">{{ lot.id }}</td>
            <td class="p-3">{{ lot.name }}</td>
            <td class="p-3">{{ lot.strain }}</td>
            <td class="flex justify-end gap-2 py-2">
              <button class="btn bg-secondary-200 text-dark hover:bg-gray-100 w-full md:w-auto" @click="createProduct(lot.id)">
                Create Product
              </button>
              <button class="btn text-dark bg-secondary-100 hover:bg-secondary-300 w-full md:w-auto" @click="openSidebar(lot)">
                View Details
              </button>
            </td>
          </tr>
          <tr v-if="lots.length === 0">
            <td colspan="8" class="text-center p-3">No lots available.</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>

    <!-- Sidebar Overlay with Transition -->
    <transition
        enter-active-class="transition-transform duration-300 ease-linear"
        leave-active-class="transition-transform duration-300 ease-linear"
        enter-from-class="translate-x-full"
        enter-to-class="translate-x-0"
        leave-from-class="translate-x-0"
        leave-to-class="translate-x-full"
    >
      <div
          v-if="sidebarVisible"
          class="fixed inset-y-0 right-0 w-4/5 h-full bg-primary-50 shadow-lg z-50"
      >
        <!-- Sidebar Content -->
        <div class="p-4 h-full">
          <button class="btn btn-sm btn-circle absolute right-2 top-2" @click="closeSidebar">✕</button>
          <h3 class="text-lg font-bold mb-4">Details for Lot: {{ selectedLot.name }}</h3>
          <p><strong>Strain:</strong> {{ selectedLot.strain }}</p>
          <p><strong>Number of Plants:</strong> {{ selectedLot.numberOfPlants }}</p>
          <p><strong>Wet Weight:</strong> {{ selectedLot.wetWeight }}</p>
          <p><strong>Dry Weight:</strong> {{ selectedLot.dryWeight }}</p>
          <p><strong>Description:</strong> {{ selectedLot.description }}</p>
          <!-- Additional details can be added here -->
        </div>
      </div>
    </transition>

    <!-- Sidebar Overlay Click-to-Close -->
    <div
        v-if="sidebarVisible"
        class="fixed inset-0 bg-black bg-opacity-50 z-40 transition-opacity duration-300 ease-linear"
        @click="closeSidebar"
    ></div>
  </div>
</template>
