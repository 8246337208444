<template>
  <section class="relative py-20 lg:py-28 bg-gradient-to-r from-dark-800 via-primary to-dark-800 text-light-50">
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
      <h1 class="text-5xl lg:text-6xl font-extrabold tracking-tight text-light-50">
        Take Control of Your Cannabis Cultivation with Advanced ERP Software
      </h1>
      <p class="mt-6 text-lg lg:text-xl text-secondary-400">
        Weed Garden’s ERP software streamlines your daily operations, enabling cannabis cultivators to focus on what they do best: growing top-tier cannabis. Simplify compliance, optimize growth, and track every stage from seed to sale with ease.
      </p>
      <div class="mt-8">
        <button @click="scrollToCTA" class="bg-primary-500 hover:bg-primary-600 text-light-50 py-4 px-8 font-bold shadow-lg rounded-lg transition-transform hover:shadow-2xl">
          Get Started with Weed Garden ERP
        </button>
      </div>
    </div>
  </section>

  <!-- Key Benefits Section -->
  <section class="relative py-20 lg:py-28 bg-gradient-to-r from-dark-800 via-primary to-dark-800 text-light-50">
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <h2 class="text-5xl font-extrabold text-light-100 mb-12 text-center">
        Weed Garden’s Cannabis ERP Solution: Built for Cultivators
      </h2>
      <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">

        <!-- Feature 1: Compliance -->
        <div class="flex items-center bg-dark-700 p-6 rounded-lg shadow-lg transition-transform hover:scale-105">
          <i class="fas fa-shield-alt text-primary-50 text-5xl mr-6"></i>
          <div>
            <h3 class="text-2xl font-bold mb-2 text-light-50">
              Effortless Cannabis Compliance
            </h3>
            <p class="text-secondary-200 leading-relaxed">
              Stay compliant with state and federal cannabis regulations with automated compliance features. Weed Garden ensures your operation remains legally compliant at every stage.
            </p>
          </div>
        </div>

        <!-- Feature 2: Inventory Management -->
        <div class="flex items-center bg-dark-700 p-6 rounded-lg shadow-lg transition-transform hover:scale-105">
          <i class="fas fa-boxes text-primary-50 text-5xl mr-6"></i>
          <div>
            <h3 class="text-2xl font-bold mb-2 text-light-50">
              Real-Time Inventory Management
            </h3>
            <p class="text-secondary-200 leading-relaxed">
              Keep track of your cannabis stock levels, monitor plant health, and manage growth stages with real-time inventory data, all in one place.
            </p>
          </div>
        </div>

        <!-- Feature 3: Harvest Tracking -->
        <div class="flex items-center bg-dark-700 p-6 rounded-lg shadow-lg transition-transform hover:scale-105">
          <i class="fas fa-seedling text-primary-50 text-5xl mr-6"></i>
          <div>
            <h3 class="text-2xl font-bold mb-2 text-light-50">
              Seed-to-Sale Cannabis Tracking
            </h3>
            <p class="text-secondary-200 leading-relaxed">
              Gain full visibility into your entire grow operation with our robust seed-to-sale tracking system. Ensure cannabis quality at every stage of the cultivation cycle.
            </p>
          </div>
        </div>

        <!-- Feature 4: Data-Driven Insights -->
        <div class="flex items-center bg-dark-700 p-6 rounded-lg shadow-lg transition-transform hover:scale-105">
          <i class="fas fa-chart-bar text-primary-50 text-5xl mr-6"></i>
          <div>
            <h3 class="text-2xl font-bold mb-2 text-light-50">
              Actionable Cannabis Insights
            </h3>
            <p class="text-secondary-200 leading-relaxed">
              Use in-depth reports to make data-driven decisions, from labor cost tracking to plant analytics, and streamline your cannabis cultivation process.
            </p>
          </div>
        </div>

        <!-- Feature 5: Custom Workflows -->
        <div class="flex items-center bg-dark-700 p-6 rounded-lg shadow-lg transition-transform hover:scale-105">
          <i class="fas fa-cogs text-primary-50 text-5xl mr-6"></i>
          <div>
            <h3 class="text-2xl font-bold mb-2 text-light-50">
              Customized Workflow Management
            </h3>
            <p class="text-secondary-200 leading-relaxed">
              Customize workflows to match your unique cannabis cultivation process. Weed Garden ERP adapts to your methods, giving you full control of your operations.
            </p>
          </div>
        </div>

        <!-- Feature 6: Compliance-Ready Reporting -->
        <div class="flex items-center bg-dark-700 p-6 rounded-lg shadow-lg transition-transform hover:scale-105">
          <i class="fas fa-file-alt text-primary-50 text-5xl mr-6"></i>
          <div>
            <h3 class="text-2xl font-bold mb-2 text-light-50">
              Compliance-Ready Reporting
            </h3>
            <p class="text-secondary-200 leading-relaxed">
              Generate compliance-ready reports effortlessly. Weed Garden ensures your data is audit-ready at any given moment.
            </p>
          </div>
        </div>

      </div>
    </div>
  </section>

  <!-- Call to Action -->
  <section class="relative py-20 lg:py-28 bg-gradient-to-r from-dark-800 via-primary to-dark-800 text-light-50">
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
      <h2 class="text-4xl font-extrabold text-light-100 mb-8">
        Ready to Optimize Your Cannabis Operation?
      </h2>
      <p class="text-lg text-secondary-200 mb-8">
        Get started today with Weed Garden’s ERP software and take your cannabis cultivation to the next level. Manage your inventory, streamline compliance, and make data-driven decisions to maximize your harvest.
      </p>
      <button class="bg-primary-500 hover:bg-primary-600 text-light-50 py-4 px-8 font-bold shadow-lg rounded-lg transition-transform hover:shadow-2xl">
        Start Using Weed Garden Today
      </button>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      isModalOpen: false
    }
  },
  methods: {
    scrollToCTA() {
      const container = document.querySelector('.cta');
      if (container) {
        container.scrollIntoView({behavior: 'smooth'});
      }
    },
  },
};
</script>

<style>
/* Button styles */
.btn-primary {
  @apply bg-tertiary hover:bg-tertiary-600 text-dark-800 px-6 py-3 rounded-lg font-medium inline-block text-xl;
}
</style>
