<template>
  <section class="relative py-20 lg:py-28 bg-gradient-to-r from-dark-800 via-primary to-dark-800 text-light-50 transition-colors duration-500">
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative z-10">
      <div class="grid grid-cols-1 lg:grid-cols-2 gap-16">
        <!-- Left: Text Content -->
        <div class="flex flex-col justify-center lg:text-left text-center">
          <h1 class="text-4xl font-extrabold tracking-tight text-light-50 leading-tight">
            <span class="text-7xl lg:text-8xl text-primary-50 font-black">METRC</span>
            <span class="text-6xl text-light-50">Integration</span><br>
            Streamline Cannabis Compliance
          </h1>
          <p class="mt-6 text-lg lg:text-xl text-secondary-400 leading-relaxed">
            Weed Garden’s seamless METRC integration ensures that cannabis operators, cultivators, and distributors remain compliant with ever-changing regulatory requirements. Automate seed-to-sale tracking, inventory management, and cannabis compliance, all while reducing operational overhead and errors.
          </p>
          <div class="mt-8">
            <button @click="scrollToCTA" class="w-full rounded-lg bg-tertiary-200 hover:bg-tertiary-800 text-dark focus:outline-none focus:ring-4 focus:ring-tertiary-400 text-xl lg:text-2xl py-4 px-8 font-bold shadow-lg transition-transform hover:text-light hover:shadow-2xl">
              Get Started with METRC Integration
            </button>
          </div>
        </div>

        <!-- Right: Image -->
        <div class="flex justify-center items-center">
<!--          <img-->
<!--              src="/img/metrc-integration.webp"-->
<!--              srcset="/img/metrc-integration-small.webp 400w, /img/metrc-integration-medium.webp 600w, /img/metrc-integration-large.webp 800w"-->
<!--              sizes="(max-width: 600px) 400px, (max-width: 1200px) 600px, 800px"-->
<!--              alt="Cannabis METRC Compliance and Integration with Weed Garden ERP"-->
<!--              class="rounded-lg shadow-lg"-->
<!--              loading="lazy" decoding="async"-->
<!--          />-->
        </div>
      </div>
    </div>
  </section>

  <!-- Section 2: Why METRC Integration is Essential -->
  <section class="relative py-20 lg:py-28 bg-gradient-to-r from-dark-800 via-primary to-dark-800 text-light-50 transition-colors duration-500">
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div class="grid grid-cols-1 lg:grid-cols-2 gap-16">
        <!-- Left: Image -->
        <div class="flex justify-center items-center">
<!--          <img-->
<!--              src="/img/compliance-medium.webp"-->
<!--              srcset="/img/compliance-small.webp 400w, /img/compliance-medium.webp 600w, /img/compliance-large.webp 800w"-->
<!--              sizes="(max-width: 600px) 400px, (max-width: 1200px) 600px, 800px"-->
<!--              alt="METRC Cannabis Compliance Software"-->
<!--              class="rounded-lg shadow-lg"-->
<!--              loading="lazy" decoding="async">-->
        </div>

        <!-- Right: Text Content -->
        <div class="flex flex-col justify-center lg:text-left">
          <h2 class="text-4xl font-extrabold text-light-50 mb-8">
            <span class="text-6xl text-primary-50 font-black">Why</span> METRC Integration Matters
          </h2>
          <p class="text-lg lg:text-xl text-secondary-200 mb-6 leading-relaxed">
            As a cannabis cultivator or distributor, staying compliant with state laws is not optional—it’s mandatory. Weed Garden’s METRC-integrated ERP software provides you with a single platform to automate regulatory compliance. Our solution helps you navigate the complexities of cannabis tracking, record-keeping, and reporting with ease, allowing you to focus on growing your business.
          </p>
          <p class="text-lg lg:text-xl text-secondary-200 leading-relaxed">
            METRC integration ensures that all your cannabis compliance data is accurate, accessible, and automatically submitted, eliminating manual entry errors and saving you hours every week.
          </p>
        </div>
      </div>
    </div>
  </section>

  <!-- Section 3: Features -->
  <section class="relative py-20 lg:py-28 bg-gradient-to-r from-dark-800 via-primary to-dark-800 text-light-50 transition-colors duration-500">
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-left">
      <h2 class="text-5xl font-extrabold text-light-100 mb-12 text-center">
        <span class="text-6xl text-primary-50 font-black">Key</span> Features of Our METRC Integration
      </h2>
      <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">

        <!-- Feature 1: Automated Compliance -->
        <div class="flex items-center bg-dark-700 p-6 rounded-lg shadow-lg transition-transform hover:scale-105">
          <i class="fas fa-check-circle text-primary-50 text-5xl mr-6" aria-hidden="true"></i>
          <div>
            <h3 class="text-2xl font-bold mb-2 text-light-50">
              <span class="text-primary-50">Automated</span> Cannabis Compliance
            </h3>
            <p class="text-secondary-200 leading-relaxed">
              Automatically track and report every stage of cannabis cultivation and distribution, from seed to sale. Stay compliant with all METRC regulations while reducing the need for manual data entry.
            </p>
          </div>
        </div>

        <!-- Feature 2: Seed-to-Sale Tracking -->
        <div class="flex items-center bg-dark-700 p-6 rounded-lg shadow-lg transition-transform hover:scale-105">
          <i class="fas fa-seedling text-primary-50 text-5xl mr-6" aria-hidden="true"></i>
          <div>
            <h3 class="text-2xl font-bold mb-2 text-light-50">
              <span class="text-primary-50">Seed-to-Sale</span> Tracking
            </h3>
            <p class="text-secondary-200 leading-relaxed">
              Complete cannabis seed-to-sale tracking ensures full traceability of your plants. From initial seed entry to final product sale, Weed Garden's METRC integration keeps you compliant at all stages.
            </p>
          </div>
        </div>

        <!-- Feature 3: Inventory Management -->
        <div class="flex items-center bg-dark-700 p-6 rounded-lg shadow-lg transition-transform hover:scale-105">
          <i class="fas fa-warehouse text-primary-50 text-5xl mr-6" aria-hidden="true"></i>
          <div>
            <h3 class="text-2xl font-bold mb-2 text-light-50">
              <span class="text-primary-50">Real-Time</span> Inventory Management
            </h3>
            <p class="text-secondary-200 leading-relaxed">
              Track cannabis inventory in real-time, including plant growth stages, product yields, and stock levels. Our system seamlessly syncs with METRC, ensuring you stay on top of your inventory and compliance.
            </p>
          </div>
        </div>

        <!-- Feature 4: Advanced Reporting -->
        <div class="flex items-center bg-dark-700 p-6 rounded-lg shadow-lg transition-transform hover:scale-105">
          <i class="fas fa-chart-pie text-primary-50 text-5xl mr-6" aria-hidden="true"></i>
          <div>
            <h3 class="text-2xl font-bold mb-2 text-light-50">
              <span class="text-primary-50">Advanced</span> METRC Reporting
            </h3>
            <p class="text-secondary-200 leading-relaxed">
              Automatically generate detailed reports required for cannabis compliance. With one-click METRC reporting, easily submit compliance data to stay up-to-date with state regulations.
            </p>
          </div>
        </div>

        <!-- Feature 5: Waste and Destruction Tracking -->
        <div class="flex items-center bg-dark-700 p-6 rounded-lg shadow-lg transition-transform hover:scale-105">
          <i class="fas fa-recycle text-primary-50 text-5xl mr-6" aria-hidden="true"></i>
          <div>
            <h3 class="text-2xl font-bold mb-2 text-light-50">
              <span class="text-primary-50">Waste</span> & Destruction Tracking
            </h3>
            <p class="text-secondary-200 leading-relaxed">
              Efficiently track cannabis waste and destruction processes, ensuring compliance with METRC's strict guidelines. Our integrated system keeps detailed records for auditing and reporting.
            </p>
          </div>
        </div>

      </div>
    </div>
  </section>

  <!-- Section 4: Call to Action -->
  <section class="relative py-20 lg:py-28 bg-gradient-to-r from-dark-800 via-primary to-dark-800 text-light-50 transition-colors duration-500">
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-left">
      <h2 class="text-4xl  font-extrabold text-light-100 mb-8 text-center">
        <span class="text-primary-50 font-black">Get Started</span> with METRC Compliance Today!
      </h2>
      <p class="text-lg lg:text-xl text-secondary-200 mb-8 text-center">
        Weed Garden's cannabis ERP with METRC integration gives you the tools to streamline compliance and optimize your cannabis cultivation or distribution business. Stay compliant, save time, and grow confidently.
      </p>
      <div class="mt-8 flex justify-center">
        <button @click="scrollToCTA" class="rounded-lg bg-tertiary-200 hover:bg-tertiary-800 text-dark focus:outline-none focus:ring-4 focus:ring-tertiary-400 text-xl lg:text-2xl py-4 px-8 font-bold shadow-lg transition-transform hover:text-light hover:shadow-2xl">
          Learn More About METRC Integration
        </button>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      isModalOpen: false
    }
  },
  methods: {
    scrollToCTA() {
      const container = document.querySelector('.cta');
      if (container) {
        container.scrollIntoView({behavior: 'smooth'});
      }
    }
  },
};
</script>

<style>
/* Button styles */
.btn-primary {
  @apply bg-tertiary hover:bg-tertiary-600 text-dark-800 px-6 py-3 rounded-lg font-medium inline-block text-xl;
}

/* General hover effect for navigation */
nav a:hover {
  @apply text-tertiary;
}

/* Scroll-to-top button */
button[aria-label="Scroll to Top"] {
  @apply fixed bottom-6 right-6 bg-tertiary text-light-50 p-3 rounded-full shadow-lg;
}

</style>
