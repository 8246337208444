<script lang="ts">
import { defineComponent } from 'vue';
import VueTurnstile from "vue-turnstile";
import services from "@/services";

export default defineComponent({
  name: "ResetPasswordPage",
  components: { VueTurnstile },
  data() {
    return {
      password: '',
      token: '',
      captchaToken: '',
      captchaVisible: true,
    };
  },
  mounted() {
    const urlParams = new URLSearchParams(window.location.search);
    this.token = urlParams.get('token') || '';
  },
  methods: {
    handleResetPassword() {
      // Logic to reset the password using the token
      if (this.token) {
        services.resetPassword({
          token: this.token,
          newPassword: this.password,
          turnstileToken: this.captchaToken
        });

        this.$router.push("/");
      } else {
        console.error('No token provided for password reset.');
      }
    },
    onCaptchaSuccess(token) {
      this.captchaToken = token;
    },
  }
});
</script>

<template>
  <div class="surface-0 flex justify-content-center min-h-screen min-w-screen overflow-hidden" style="padding: 25px 0;">
    <div class="grid justify-content-center p-3 lg:p-0 min-width-80">
      <div class="col-12 xl:col-6 gradient-border-top">
        <div class="h-full w-full m-0 px-4 gradient-border-bot">
          <div class="flex items-center justify-content-center mb-4">
            <img src="/site-logo.webp" class="logo-img" />
          </div>
          <form @submit.prevent="handleResetPassword" class="w-full md:w-6 mx-auto">
            <div class="p-field mb-5">
              <FloatLabel>
                <InputText id="password" v-model="password" type="password" class="w-full input-text" placeholder="Enter your new password" />
                <label for="password">New Password</label>
              </FloatLabel>
            </div>
            <div class="captcha-container mb-4" v-if="captchaVisible">
              <vue-turnstile :key="captchaKey" site-key="0x4AAAAAAAVtMvcGcbAP_EJ7" v-model="captchaToken" theme="dark" @success="onCaptchaSuccess" />
            </div>
            <Button :disabled="!captchaToken" class="w-full p-3 text-xl mb-4" label="Reset Password" type="submit" outlined raised />
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
/* Add your styles here */
</style>
