<script>
import {defineComponent} from "vue";
import services from "@/services";

export default defineComponent({
  name: 'SourcesManagementPage',
  data() {
    return {
      isLoading: true,
      isAddMode: false,
      showAddEditSourceDialog: false,
      source: {
        companyName: '',
        contactName: '',
        contactPhone: '',
        contactEmail: '',
        website: '',
        address: ''
      },
      sources: []
    }
  },
  async mounted() {
    await this.loadData();
    setTimeout(() => {
      this.isLoading = false;  
    }, 500);
  },
  methods: {
    async loadData() {
      const apiResponse = await services.listSources();
      this.sources = apiResponse.data;
    },
    async addSource() {
      await services.addSource(this.source);
      this.$toast.add({ severity: 'success', summary: 'Success', detail: 'Source Added Successfully', life: 3000 });
      this.showAddEditSourceDialog = false;
      this.resetForm();
      await this.loadData();
    },
    showAddDialog() {
      this.resetForm();
      this.isAddMode = true;
      this.showAddEditSourceDialog = true;
    },
    showEditDialog(data) {
      this.resetForm();
      this.source = { ...data };
      this.isAddMode = false;
      this.showAddEditSourceDialog = true;
    },
    async editSource() {
      const source = { ...this.source };
      const { id, companyName } = this.source;

      await services.editSource(id, source);
      await this.loadData();

      this.resetForm();
      
      this.showAddEditSourceDialog = false;
      this.$toast.add({ severity: 'success', summary: 'Success', detail: `Successfully edited ${companyName}`, life: 3000 });
    },
    async deleteSource(data) {
      this.$confirm.require({
        message: `Are you sure you want to delete ${data.name}`,
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        rejectClass: 'p-button-secondary p-button-outlined',
        rejectIcon: 'pi pi-times',
        rejectLabel: 'Cancel',
        acceptLabel: 'Yes',
        acceptClass: 'p-button-outlined',
        acceptIcon: 'pi pi-check',
        accept: async () => {
          await services.deleteSource(data.id);
          await this.loadData();
          this.$toast.add({ severity: 'success', summary: 'Success', detail: `Successfully deleted ${data.name}`, life: 3000 });
        },
        reject: () => {
          this.$toast.add({ severity: 'info', summary: 'Cancelled', detail: 'Delete cancelled', life: 3000 });
        }
      });
    },
    resetForm() {
      this.source = {
        id:  '',
        companyName: '',
        contactName: '',
        contactPhone: '',
        contactEmail: '',
        website: '',
        address: ''
      };
    }
  }
})
</script>

<template>
  <div id="SourcesManagementPage" class="min-h-screen relative bg-gradient-to-r from-dark-800 via-primary to-dark-800 text-light-50 transition-colors duration-500">
    <!-- Sources Table -->
    <div class="flex-grow p-6 shadow-lg flex flex-col">
      <h1 class="text-2xl font-bold mb-4 text-light">Lot Sources</h1>
      <div class="flex justify-between items-center sticky top-0 z-10 p-4">
        <!-- Add Source Button -->
        <button class="btn w-full md:w-auto text-white hover:bg-secondary-600 hover:text-dark" @click="showAddDialog">
          Add New Source
        </button>
      </div>
      <div class="overflow-x-auto">
        <!-- Table for larger screens -->
        <table class="table w-full">
          <thead class="text-gray-100">
          <tr>
            <th class="p-3">Company Name</th>
            <th class="p-3">Contact Name</th>
            <th class="p-3">Contact Phone</th>
            <th class="p-3">Contact Email</th>
            <th class="p-3">Website</th>
            <th class="p-3">Address</th>
            <th class="p-3 text-right">Actions</th>
          </tr>
          </thead>
          <tbody class="text-gray-100">
          <tr v-for="source in sources" :key="source.id" class="hover:bg-primary-500 transition">
            <td class="p-3">{{ source.companyName }}</td>
            <td class="p-3">{{ source.contactName }}</td>
            <td class="p-3">{{ source.contactPhone }}</td>
            <td class="p-3">{{ source.contactEmail }}</td>
            <td class="p-3">{{ source.website }}</td>
            <td class="p-3">{{ source.address }}</td>
            <td class="flex justify-end gap-2 py-2">
              <button class="btn bg-secondary-200 text-dark hover:bg-gray-100 w-full md:w-auto tooltip tooltip-left" data-tip="Edit Source" @click="showEditDialog(source)">
                <i class="fa fa-pencil"></i>
              </button>
              <button class="btn text-dark bg-tertiary-100 hover:bg-tertiary-700 w-full md:w-auto tooltip tooltip-left" data-tip="Delete Source" @click="deleteSource(source)">
                <i class="fa fa-trash"></i>
              </button>
            </td>
          </tr>
          <tr v-if="sources.length === 0">
            <td colspan="7" class="text-center p-3">No sources available.</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>

    <!-- Add/Edit Source Dialog -->
    <div v-if="showAddEditSourceDialog" class="modal modal-open">
      <div class="modal-box relative bg-primary-50">
        <!-- Close Button -->
        <button class="btn btn-sm btn-circle absolute right-2 top-2" @click="showAddEditSourceDialog = false">✕</button>
        <h3 class="font-bold text-lg mb-4" v-if="isAddMode">Add New Source</h3>
        <h3 class="font-bold text-lg mb-4" v-else>Edit Source</h3>

        <!-- Form -->
        <form @submit.prevent="isAddMode ? addSource() : editSource()">
          <div class="grid grid-cols-1 gap-4">
            <div>
              <label class="label" for="companyName">Company Name</label>
              <input type="text" id="companyName" v-model="source.companyName" class="input input-bordered w-full" required />
            </div>
            <div>
              <label class="label" for="contactName">Contact Name</label>
              <input type="text" id="contactName" v-model="source.contactName" class="input input-bordered w-full" required />
            </div>
            <div>
              <label class="label" for="contactPhone">Contact Phone</label>
              <input type="text" id="contactPhone" v-model="source.contactPhone" class="input input-bordered w-full" required />
            </div>
            <div>
              <label class="label" for="contactEmail">Contact Email</label>
              <input type="email" id="contactEmail" v-model="source.contactEmail" class="input input-bordered w-full" required />
            </div>
            <div>
              <label class="label" for="website">Website</label>
              <input type="url" id="website" v-model="source.website" class="input input-bordered w-full" required />
            </div>
            <div>
              <label class="label" for="address">Address</label>
              <input type="text" id="address" v-model="source.address" class="input input-bordered w-full" required />
            </div>
          </div>

          <div class="modal-action flex justify-end">
            <button type="button" class="btn text-dark bg-secondary-100 hover:bg-secondary-300" @click="showAddEditSourceDialog = false">Cancel</button>
            <button type="submit" class="btn btn-primary ml-2">Submit</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
