<template>
    <fieldset class="border border-gray-300 rounded-md p-4 mb-6">
      <legend class="text-lg font-bold">Source Details</legend>
      <div class="mb-4">
        <label for="source" class="block font-semibold mb-2">Source</label>
        <select v-model="selectedSource" class="select select-bordered w-full" placeholder="Select a Source">
          <option v-for="source in sourcesList" :key="source.id" :value="source.id">
            {{ source.companyName }}
          </option>
        </select>
      </div>

      <div v-if="sourceDetails" class="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div>
          <label for="companyName" class="block font-semibold mb-2">Company Name</label>
          <input id="companyName" v-model="sourceDetails.companyName" class="input input-bordered w-full" readonly />
        </div>
        <div>
          <label for="contactName" class="block font-semibold mb-2">Contact Name</label>
          <input id="contactName" v-model="sourceDetails.contactName" class="input input-bordered w-full" readonly />
        </div>
        <div>
          <label for="contactPhone" class="block font-semibold mb-2">Contact Phone</label>
          <input id="contactPhone" v-model="sourceDetails.contactPhone" class="input input-bordered w-full" readonly />
        </div>
        <div>
          <label for="contactEmail" class="block font-semibold mb-2">Contact Email</label>
          <input id="contactEmail" v-model="sourceDetails.contactEmail" class="input input-bordered w-full" readonly />
        </div>
        <div>
          <label for="website" class="block font-semibold mb-2">Website</label>
          <input id="website" v-model="sourceDetails.website" class="input input-bordered w-full" readonly />
        </div>
        <div>
          <label for="location" class="block font-semibold mb-2">Address / Location</label>
          <input id="location" v-model="sourceDetails.location" class="input input-bordered w-full" readonly />
        </div>
        <div>
          <label for="sourceType" class="block font-semibold mb-2">Source Type</label>
          <input id="sourceType" v-model="sourceType" class="input input-bordered w-full" readonly />
        </div>
        <div>
          <label for="upc" class="block font-semibold mb-2">UPC</label>
          <input id="upc" v-model="sourceDetails.upc" class="input input-bordered w-full" readonly />
        </div>
      </div>
    </fieldset>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import services from "@/services.js";

export default defineComponent({
  name: "SourceDetailsComponent",
  props: {
    lotId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      sourceDetails: {
        upc: "",
        isClone: false,
        isSeed: true,
        companyName: "",
        contactName: "",
        location: "",
        contactPhone: "",
        contactEmail: "",
        website: ""
      },
      selectedSource: null,
      sourcesList: [],
      isInitialized: false
    };
  },
  computed: {
    sourceType() {
      return this.sourceDetails.isSeed ? 'Seed' : 'Clone';
    }
  },
  methods: {
    async fetchSourceDetails() {
      try {
        const response = await services.getSourceDetails(this.lotId);
        this.sourceDetails = response.data;

        const listSourcesApiResponse = await services.listSources();
        this.sourcesList = listSourcesApiResponse.data;
        if (this.sourcesList) {
          this.selectedSource = this.sourcesList.find(source => source.id === this.sourceDetails.id)?.id || null;
        }
      } catch (error) {
        console.error("Error fetching source details:", error);
      }
    },
    async updateSourceDetails() {
      const selected = this.sourcesList.find(source => source.id === this.selectedSource);
      if (selected) {
        this.sourceDetails = selected;
        await services.updateLotSource(this.lotId, selected.id);
      }
    }
  },
  watch: {
    selectedSource(newValue, oldValue) {
      if (this.isInitialized && newValue !== oldValue) {
        this.updateSourceDetails();
      }
    }
  },
  async mounted() {
    await this.fetchSourceDetails();
    this.isInitialized = true;
  }
});
</script>
