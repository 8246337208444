<template>
  <div class="relative py-20 lg:py-28 bg-gradient-to-r from-dark-800 via-primary to-dark-800 text-light-50 transition-colors duration-500">
    <!-- Sidebar with vertical tabs -->
    <div class="w-1/5 text-light-200 h-full flex flex-col">
      <h2 class="text-xl font-bold p-4 text-light-50">Lot Details</h2>
      <ul class="flex flex-col space-y-2 p-4">
        <li
            v-for="(tab, index) in tabs"
            :key="index"
            :class="{
            'bg-dark-600 text-light-50 border-l-4 border-orange-500': selectedTabIndex === index,
            'hover:bg-dark-600 hover:text-light-50': selectedTabIndex !== index
          }"
            class="p-3 rounded-l cursor-pointer"
            @click="selectedTabIndex = index"
        >
          <i :class="tab.icon" class="mr-2"></i> {{ tab.label }}
        </li>
      </ul>
    </div>

    <!-- Tab Content Area with consistent background -->
    <div class="flex-1 h-full overflow-y-auto p-6 text-light-50">
      <div v-if="selectedTabIndex === 0">
        <h2 class="text-2xl font-bold">Details</h2>
        <LotDetailsComponent :lot-id="lotId" />
      </div>
      <div v-else-if="selectedTabIndex === 1">
        <h2 class="text-2xl font-bold">Grow Notes</h2>
        <LotNotesTabComponent :lot-id="lotId" />
      </div>
      <div v-else-if="selectedTabIndex === 2">
        <h2 class="text-2xl font-bold">Images</h2>
        <LotImagesTabComponent :lot-id="lotId" />
      </div>
      <div v-else-if="selectedTabIndex === 3">
        <h2 class="text-2xl font-bold">Expenses</h2>
        <LotExpensesTabComponent :lot-id="lotId" />
      </div>
      <div v-else-if="selectedTabIndex === 4">
        <h2 class="text-2xl font-bold">Reporting</h2>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted } from 'vue';
import services from '@/services.js';
import LotDetailsComponent from "@/components/LotDetailsComponent.vue";
import LotNotesTabComponent from "@/views/App/LotNotesTabComponent.vue";
import LotImagesTabComponent from "@/views/App/LotImagesTabComponent.vue";
import LotExpensesTabComponent from "@/views/App/LotExpensesTabComponent.vue";

export default defineComponent({
  name: 'LotDetailPage',
  props: {
    lotId: {
      type: String,
      required: true
    }
  },
  components: {
    LotDetailsComponent,
    LotNotesTabComponent,
    LotImagesTabComponent,
    LotExpensesTabComponent
  },
  setup(props) {
    const selectedTabIndex = ref(0);
    const tabs = ref([
      { label: 'Lot Details', icon: 'pi pi-pencil' },
      { label: 'Notes', icon: 'pi pi-file' },
      { label: 'Images', icon: 'pi pi-image' },
      { label: 'Costs', icon: 'pi pi-dollar' },
      { label: 'Reporting', icon: 'pi pi-chart' }
    ]);

    const lotData = ref(null);
    const originalLotDetails = ref(null);

    // Fetch lot details on mount
    onMounted(async () => {
      await fetchLotDetails();
    });

    // Function to fetch lot details
    const fetchLotDetails = async () => {
      try {
        const lotResponse = await services.getLotDetails(props.lotId);
        lotData.value = { ...lotResponse.data };  // store the current data
        originalLotDetails.value = JSON.stringify(lotResponse.data); // store original data as string for comparison
      } catch (error) {
        console.error("Error fetching lot details:", error);
      }
    };

    // Function to detect if a lot detail field has changed
    const hasLotChanged = () => {
      return JSON.stringify(lotData.value) !== originalLotDetails.value;
    };

    // Function to patch only the changed fields
    const updateLotDetails = async () => {
      if (hasLotChanged()) {
        const changedFields = getChangedFields();
        if (Object.keys(changedFields).length > 0) {
          try {
            await services.updateLotDetails(props.lotId, changedFields);
            originalLotDetails.value = JSON.stringify(lotData.value);  // reset original data after successful patch
            console.log('Lot details updated successfully');
          } catch (error) {
            console.error('Error updating lot details:', error);
          }
        }
      }
    };

    // Get only the changed fields to send in the PATCH request
    const getChangedFields = () => {
      const changedFields = {};
      for (const key in lotData.value) {
        if (lotData.value[key] !== JSON.parse(originalLotDetails.value)[key]) {
          changedFields[key] = lotData.value[key];  // only include changed fields
        }
      }
      return changedFields;
    };

    return {
      selectedTabIndex,
      tabs,
      lotData,
      fetchLotDetails,
      updateLotDetails,
      hasLotChanged
    };
  },
  methods: {
    async updateLocation(lot, newLocationId) {
      try {
        const location = this.locations.find(loc => loc.id === newLocationId);
        if (location) {
          lot.location = location;
          await services.saveLotLocation(lot.id, newLocationId);
          this.$toast.add({
            severity: 'success',
            summary: 'Success',
            detail: 'Location updated successfully',
            life: 3000,
          });
        } else {
          throw new Error('Location not found');
        }
      } catch (error) {
        console.error('Error in updateLocation:', error);
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Failed to update location',
          life: 3000,
        });
      }
    }
  }
});
</script>

