import './assets/reset.css';
import CookieConsentVue from './CookieConsentVue.js'

import { createApp } from 'vue';
import { createPinia } from 'pinia';
import App from './App.vue';
import router from './router';

const app = createApp(App);

app.use(createPinia());
app.use(router);
app.use(CookieConsentVue, {

    categories: {
        necessary: {
            enabled: true,  // this category is enabled by default
            readOnly: true  // this category cannot be disabled
        },
        analytics: {}
    },

    language: {
        default: 'en',
        translations: {
            en: {
                consentModal: {
                    title: 'We use cookies to enhance your experience',
                    description: 'We use cookies to improve functionality, analyze traffic, and personalize content.',
                    acceptAllBtn: 'Accept all',
                    acceptNecessaryBtn: 'Reject all',
                    showPreferencesBtn: 'Manage preferences'
                },
                preferencesModal: {
                    title: 'Cookie Preferences',
                    acceptAllBtn: 'Accept all',
                    acceptNecessaryBtn: 'Reject all',
                    savePreferencesBtn: 'Save preferences',
                    closeIconLabel: 'Close',
                    sections: [
                        {
                            title: 'Cookies: Not just for eating!',
                            description: 'We use cookies to improve your browsing experience.'
                        },
                        {
                            title: 'Strictly Necessary Cookies',
                            description: 'These cookies are required for basic functionality and cannot be disabled.',
                            linkedCategory: 'necessary'
                        },
                        {
                            title: 'Performance and Analytics',
                            description: 'These cookies help us understand how you use our site, with anonymized data that cannot identify you.',
                            linkedCategory: 'analytics'
                        },
                        {
                            title: 'Need more info?',
                            description: 'If you have questions about our cookie policy, feel free to reach out at privacy@weed-garden.com'
                        }
                    ]
                }
            }
        }
    }
});

app.mount('#app');
