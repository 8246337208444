<script lang="ts">
import {defineComponent} from 'vue'
import QRCodeVue3 from 'qrcode-vue3';
import {printQRCode} from "@/components/QR/printQRCode.js";

export default defineComponent({
  name: "QRPreview" ,
  components: {QRCodeVue3},
  props: [
    'lot'
  ],
  data() {
    return {
      
    }
  },
  methods: {
    printQRCode,
    cancelPreview() {
      this.$emit('cancelPreview');
    }
  }
})
</script>

<template>
  <h3 class="text-lg font-bold mb-4">QR Code for {{ lot.name }}</h3>
  <div class="flex justify-center">
    <QRCodeVue3 :value="lot.qrCode" height="200" width="200"/>
  </div>
  <div class="mt-4 flex justify-end gap-2">
    <button @click="cancelPreview" type="button" class="btn bg-secondary hover:bg-secondary-700 text-dark mt-4">Cancel</button>
    <button class="btn bg-tertiary-500 hover:bg-tertiary-700 text-dark mt-4" @click="printQRCode(lot.qrCode, lot.name)">Print QR Code</button>
  </div>
</template>