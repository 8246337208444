import { createRouter, createWebHistory } from 'vue-router';
import ResetPasswordPage from "@/views/User/ResetPasswordPage.vue";
import HomePage from "@/views/Home/HomePage.vue";
import LoginPage from "@/views/User/LoginPage.vue";
import NotFoundPage from "@/views/Home/NotFoundPage.vue";
import LotsPage from "@/views/App/LotsPage.vue";
import SourcesManagementPage from "@/views/App/LotSourceListPage.vue";
import LotDetailPageDirect from "@/views/App/LotDetailPageDirect.vue";
import PrivacyPolicyPage from "@/views/Policies/PrivacyPolicyPage.vue";
import DisclaimerPage from "@/views/Policies/DisclaimerPage.vue";
import ContactPage from "@/views/Home/ContactPage.vue";
import TeamManagementPage from "@/views/User/TeamManagementPage.vue";
import TermsOfService from "@/views/Policies/TermsOfService.vue";
import Definitions from "@/views/Utility/Definitions.vue";
import TrashedLotsPage from "@/views/App/LotTrashPage.vue";
import HarvestProductPage from "@/views/App/LotHarvestListPage.vue";
import MarketingLandingPage from "@/views/Home/MarketingLandingPage.vue";
import MetrcLandingPage from "@/views/Home/MetrcLandingPage.vue";
import AccessibilityPage from '@/views/Policies/AccessibilityPage.vue';

export const routes = [
    {
        path: '/',
        component: HomePage,
        meta: { label: 'Front Page', includeInMenu: true, icon: 'fas fa-newspaper' },
    },
    {
        path: '/login',
        component: LoginPage,
        meta: { label: 'Login', includeInMenu: false },
    },
    {
        path: '/reset-password',
        component: ResetPasswordPage,
        meta: { label: 'Reset Password', includeInMenu: false },
    },
    {
        path: '/privacy-policy',
        component: PrivacyPolicyPage,
        meta: { label: 'Privacy Policy', includeInMenu: false, icon: 'fas fa-user-shield' },
    },
    {
      path: '/cannabis-erp-software',
      component: MarketingLandingPage,
      meta: { label: 'Cannabis ERP Software', includeInMenu: false, icon: 'fas fa-external-link' },  
    },
    {
        path: '/metrc-cannabis-integration',
        component: MetrcLandingPage,
        meta: { label: 'Metrc Cannabis Integration', includeInMenu: false, icon: 'fas fa-external-link' },
    },
    {
        path: '/accessibility',
        component: AccessibilityPage,
        meta: { label: 'Metrc Cannabis Integration', includeInMenu: false, icon: 'fas fa-external-link' },
    },
    {
      path: '/disclaimer',
      component: DisclaimerPage,
      meta: { label: 'Disclaimer', includeInMenu: false, icon: 'fas fa-user-shield' },  
    },
    {
        path: '/terms-of-service',
        component: TermsOfService,
        meta: { label: 'TermsOfService', includeInMenu: false, icon: 'fas fa-user-shield' },
    },
    {
      path: '/glossary',
      component: Definitions,
      meta: { label: 'Glossary', includeInMenu: true, icon: 'fas fa-book-open' },  
    },
    {
      path: '/contact',
      component: ContactPage,
      meta: { label: 'Contact', includeInMenu: false, icon: 'fas fa-envelope' },  
    },
    {
        path: '/app',
        meta: { requiresAuth: true, includeInMenu: false }, // Base route for authenticated routes
        children: [
            {
                path: 'lot-management',
                component: LotsPage,
                meta: { requiresAuth: true, label: 'Lot Locations', includeInMenu: true, icon: 'fas fa-boxes' }, // Included in the menu
            },
            {
                path: 'source-management',
                component: SourcesManagementPage,
                meta: { requiresAuth: true, label: 'Lot Sources', includeInMenu: true, sub: true, icon: 'fas fa-leaf' }, // Included in the menu
            },
            {
                path: 'harvest',
                component: HarvestProductPage,
                meta: { requiresAuth: true, label: 'Lot Harvests', includeInMenu: true, sub: true, icon: 'fas fa-joint' }, // Included in the menu
            },
            {
                path: 'trashed-lots',
                component: TrashedLotsPage,
                meta: { requiresAuth: true, label: 'Lot Trash', includeInMenu: true, sub: true, icon: 'fas fa-trash' }, // Included in the menu
            },
            {
                path: 'team-management',
                component: TeamManagementPage,
                meta: { requiresAuth: true, label: 'Team Management', includeInMenu: false, icon: 'fas fa-users' }, // Included in the menu
            },
            {
                path: 'product-lots/:lotid',
                component: LotDetailPageDirect,
                meta: { requiresAuth: true, label: 'Lot Detail', includeInMenu: false }, // Not included in the menu
            },
            {
                path: ':catchAll(.*)',
                component: NotFoundPage,
                meta: { includeInMenu: false }, // Not included in the menu
            },
        ],
    },
    {
        path: '/:catchAll(.*)',
        component: NotFoundPage,
        meta: { includeInMenu: false }, // Not included in the menu
    },
];

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes,
    scrollBehavior(to, from, savedPosition) {
        const container = document.querySelector('.scrollable-container');

        if (savedPosition) {
            return savedPosition;
        } else {
            if (container) {
                container.scrollTo({ top: 0, left: 0, behavior: 'auto' });
            }
            return { top: 0, left: 0 };
        }
    }
});

router.beforeEach((to, from, next) => {
    const isAuthenticated = !!localStorage.getItem("user-token");

    if (to.matched.some(record => record.meta.requiresAuth) && !isAuthenticated) {
        next('/login');
    } else if (to.path === '/login' && isAuthenticated) {
        next('/app/lot-management');
    } else {
        next();
    }
});

export default router;
