<template>
  <div class="add-expense" style="width: 100%;">
    <div class="grid">
      <div class="field col-3">
        <div class="flex flex-column">
          <label for="description">Purchase Date</label>
          <Calendar v-model="newExpense.purchaseDate" date-format="mm/dd/yy" :showIcon="true" showButtonBar />
        </div>
      </div>
      <div class="field col-3">
        <div class="flex flex-column">
          <label for="description">Supplier</label>
          <InputText id="description" v-model="newExpense.supplier" />
        </div>
      </div>
      <div class="field col-3">
        <div class="flex flex-column">
          <label for="description">Description</label>
          <InputText id="description" v-model="newExpense.description" />
        </div>
      </div>
      <div class="field col-3">
        <div class="flex flex-column">
          <label for="totalCost">Total Cost</label>
          <InputNumber id="totalCost" v-model="newExpense.totalCost" inputId="currency-us" mode="currency" currency="USD" locale="en-US"  />
        </div>
      </div>
      <div class="field col-12">
        <div style="display: flex; justify-content: flex-end; align-items: center; padding-bottom: 15px; width: 100%;">
          <Button @click="addExpense" style="align-self: flex-start; width: auto;">Add Expense</Button>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  data() {
    return {
      newExpense: {
        id: null,
        purchaseDate: new Date(),
        supplier: '',
        description: '',
        preTaxCost: 0,
        taxCost: 0,
        totalCost: 0
      },
    };
  },
  methods: {
    addExpense() {
      this.$emit('add', { ...this.newExpense });
      this.resetForm();
    },
    resetForm() {
      this.newExpense = {
        id: null,
        description: '',
        costBeforeTax: 0,
        tax: 0
      };
    }
  }
};
</script>
<style scoped>
.add-expense .field {
  margin-bottom: .5rem;
}
</style>
