<template>
  <div ref="glossaryContainer" class="py-16 bg-primary-200 text-light-50">
    <div class="max-w-7xl px-4 sm:px-6 lg:px-8">
      <!-- Heading Section -->
      <div class="mb-10 text-left">
        <h1 class="text-5xl font-extrabold text-light-50 tracking-tight mb-4">This glossary breaks down key terms and
          concepts</h1>
        <p class="text-xl text-secondary-200 mb-6">
          Whether it's location tracking, environmental monitoring, or compliance, you'll get clear definitions and
          insights into why these terms matter and how they’re used within your grow operations.
        </p>
      </div>

      <!-- Search Bar -->
      <div class="relative mb-8 max-w-lg">
        <!-- Larger search icon inside the input -->
        <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="#F2F2E6" stroke="#404040" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-search">
            <circle cx="11" cy="11" r="8"></circle>
            <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
          </svg>
        </div>

        <!-- Input with padding adjusted to accommodate the icon -->
        <input
            v-model="searchQuery"
            type="text"
            placeholder="Search terms..."
            class="block w-full pl-12 pr-4 py-2 bg-light-50 text-dark-600 rounded-md focus:outline-none focus:ring-2 focus:ring-primary-600"
        />
      </div>


      <!-- Glossary Items -->
      <div v-for="category in filteredGlossaryItems" :key="category.category" class="mb-10">
        <h2 class="text-4xl font-extrabold text-light-100 mb-4">{{ category.category }}</h2>
        <ol class="list-none pl-0">
          <li v-for="term in category.terms" :key="term.term" class="border-b border-dark-200 pb-4 mb-4">
            <strong class="text-2xl text-light-50">{{ term.term }}</strong>
            <ul class="list-disc pl-5 mt-2 space-y-2 text-lg text-secondary-200">
              <li><strong>Definition</strong>: {{ term.definition }}</li>
              <li><strong>Why It Matters</strong>: {{ term.whyItMatters }}</li>
              <li><strong>How It's Used</strong>: {{ term.howItsUsed }}</li>
              <li><strong>Where It Applies</strong>: {{ term.whereItApplies }}</li>
            </ul>
          </li>
        </ol>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, nextTick } from 'vue';

export default defineComponent({
  name: "Definitions",
  data() {
    return {
      searchQuery: '',
      glossaryItems: []
    };
  },
  computed: {
    filteredGlossaryItems() {
      if (!this.searchQuery) {
        return this.glossaryItems;
      }
      const query = this.searchQuery.toLowerCase();
      return this.glossaryItems.map(category => ({
        ...category,
        terms: category.terms.filter(term =>
            term.term.toLowerCase().includes(query) ||
            term.definition.toLowerCase().includes(query) ||
            term.whyItMatters.toLowerCase().includes(query) ||
            term.howItsUsed.toLowerCase().includes(query) ||
            term.whereItApplies.toLowerCase().includes(query)
        )
      })).filter(category => category.terms.length > 0);
    }
  },
  methods: {
    async loadGlossaryItems() {
      try {
        const response = await fetch('/glossaryItems.json');
        this.glossaryItems = await response.json();
      } catch (error) {
        console.error('Failed to load glossary items:', error);
      }
    },
    highlightSearchTerm() {
      nextTick(() => {
        const instance = new Mark(this.$refs.glossaryContainer);
        instance.unmark({
          done: () => {
            if (this.searchQuery) {
              instance.mark(this.searchQuery);
            }
          }
        });
      });
    }
  },
  created() {
    this.loadGlossaryItems();
  }
});
</script>

<style scoped>
/* Using Tailwind for styles, no additional custom styles needed */
</style>
