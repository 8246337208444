<template>
    <nav class="p-4 bg-dark-900 text-light-50">
      <!-- Desktop Navigation -->
      <ul class="justify-center hidden gap-6 space-x-4 sm:flex">
        
        <!-- Terms of Service -->
        <li>
          <router-link 
            :class="linkClasses('/terms-of-service')" 
            to="/terms-of-service"
            aria-label="Read our Terms of Service" 
            title="Read our Terms of Service"
          >
            <i class="mr-2 fal fa-file-contract" aria-hidden="true"></i>
            Terms of Service
          </router-link>
        </li>
  
        <!-- Privacy Policy -->
        <li>
          <router-link 
            :class="linkClasses('/privacy-policy')" 
            to="/privacy-policy"
            aria-label="Read our Privacy Policy" 
            title="Read our Privacy Policy"
          >
            <i class="mr-2 fal fa-shield-alt" aria-hidden="true"></i>
            Privacy Policy
          </router-link>
        </li>
  
        <!-- Disclaimer -->
        <li>
          <router-link 
            :class="linkClasses('/disclaimer')" 
            to="/disclaimer"
            aria-label="Read our Disclaimer" 
            title="Read our Disclaimer"
          >
            <i class="mr-2 fal fa-exclamation-triangle" aria-hidden="true"></i>
            Disclaimer
          </router-link>
        </li>
  
        <!-- Accessibility -->
        <li>
          <router-link 
            :class="linkClasses('/accessibility')" 
            to="/accessibility"
            aria-label="Read our Accessibility Statement" 
            title="Read our Accessibility Statement"
          >
            <i class="mr-2 fal fa-universal-access" aria-hidden="true"></i>
            Accessibility
          </router-link>
        </li>
      </ul>
  
      <!-- Mobile Dropdown Navigation -->
      <div class="block sm:hidden">
        <button @click="toggleDropdown" class="p-4 bg-dark-900 text-light-50 focus:outline-none">
          Menu
        </button>
        <nav v-if="isDropdownOpen" class="p-4 bg-dark-900 text-light-50">
          <ul class="flex flex-col space-y-2">
            <li v-for="link in links" :key="link.text">
              <router-link 
                :to="link.href" 
                class="hover:text-primary-100"
                :aria-label="link.ariaLabel"
                :title="link.title"
              >
                <i :class="`mr-2 ${link.icon}`" aria-hidden="true"></i>
                {{ link.text }}
              </router-link>
            </li>
          </ul>
        </nav>
      </div>
    </nav>
  </template>
  
  <script setup lang="ts">
  import { ref } from "vue";
  import { useRoute } from "vue-router";
  
  const route = useRoute();
  const isDropdownOpen = ref(false);
  
  const links = [
    { href: "/terms-of-service", text: "Terms of Service", icon: "fal fa-file-contract", ariaLabel: "Read our Terms of Service", title: "Read our Terms of Service" },
    { href: "/privacy-policy", text: "Privacy Policy", icon: "fal fa-shield-alt", ariaLabel: "Read our Privacy Policy", title: "Read our Privacy Policy" },
    { href: "/disclaimer", text: "Disclaimer", icon: "fal fa-exclamation-triangle", ariaLabel: "Read our Disclaimer", title: "Read our Disclaimer" },
    { href: "/accessibility", text: "Accessibility", icon: "fal fa-universal-access", ariaLabel: "Read our Accessibility Statement", title: "Read our Accessibility Statement" },
    { href: "/help-center", text: "Help Center", icon: "fal fa-question-circle", ariaLabel: "Access our Help Center", title: "Go to the Help Center" },
    { href: "/contact", text: "Contact Us", icon: "fal fa-envelope", ariaLabel: "Contact us for support or inquiries", title: "Contact us" },
    { href: "/bug-report", text: "Report a Bug", icon: "fal fa-bug", ariaLabel: "Report a bug or issue with the app", title: "Report a Bug" },
    { href: "/changelog", text: "Changelog", icon: "fal fa-code-branch", ariaLabel: "View the latest release notes and updates", title: "Changelog" },
    { href: "/community", text: "Community", icon: "fal fa-users", ariaLabel: "Join our community or support forum", title: "Join the Community" }
  ];
  
  function linkClasses(href: string) {
    return route.path === href
      ? 'font-bold text-primary-100'
      : 'hover:text-primary-100';
  }
  
  function toggleDropdown() {
    isDropdownOpen.value = !isDropdownOpen.value;
  }
  </script>
  